/*
    Template Name:      Enabled Mobile Templates
    Theme URL:          http://enableds.com
    Author:             Enabled
    Author URI:         http://themeforest.net/user/Enabled?ref=Enabled
    Version:            10.0
    Envato License:     Regular or Extended via ThemeForest
    Plugin Licenses:    Each Plugin has it's indivudal license attached
*/


/*! * Splide.js * Version: Enabled Modified * License  : MIT * Copyright: Naotoshi Fujita */
!(function () {
  var t = {
    d(n, e) {
      for (const i in e)
        t.o(e, i) &&
          !t.o(n, i) &&
          Object.defineProperty(n, i, { enumerable: !0, get: e[i] });
    },
    o(t, n) {
      return Object.prototype.hasOwnProperty.call(t, n);
    },
    r(t) {
      typeof Symbol !== "undefined" &&
        Symbol.toStringTag &&
        Object.defineProperty(t, Symbol.toStringTag, { value: "Module" }),
        Object.defineProperty(t, "__esModule", { value: !0 });
    },
  };
  const n = {};
  t.r(n),
    t.d(n, {
      CREATED() {
        return R;
      },
      DESTROYED() {
        return X;
      },
      IDLE() {
        return F;
      },
      MOUNTED() {
        return B;
      },
      MOVING() {
        return G;
      },
    });
  function e() {
    return (e =
      Object.assign ||
      function (t) {
        for (let n = 1; n < arguments.length; n++) {
          const e = arguments[n];
          for (const i in e)
            Object.prototype.hasOwnProperty.call(e, i) && (t[i] = e[i]);
        }
        return t;
      }).apply(this, arguments);
  }
  const i = Object.keys;
  function o(t, n) {
    i(t).some((e, i) => n(t[e], e, i));
  }
  function r(t) {
    return i(t).map((n) => t[n]);
  }
  function s(t) {
    return typeof t === "object";
  }
  function a(t, n) {
    const i = { ...t };
    return (
      o(n, (t, n) => {
        s(t) ? (s(i[n]) || (i[n] = {}), (i[n] = a(i[n], t))) : (i[n] = t);
      }),
      i
    );
  }
  function u(t) {
    return Array.isArray(t) ? t : [t];
  }
  function c(t, n, e) {
    return Math.min(Math.max(t, n > e ? e : n), n > e ? n : e);
  }
  function d(t, n) {
    let e = 0;
    return t.replace(/%s/g, () => u(n)[e++]);
  }
  function f(t) {
    const n = typeof t;
    return n === "number" && t > 0
      ? `${parseFloat(t)}px`
      : n === "string"
      ? t
      : "";
  }
  function l(t) {
    return t < 10 ? `0${t}` : t;
  }
  function h(t, n) {
    if (typeof n === "string") {
      const e = m("div", {});
      E(e, { position: "absolute", width: n }),
        w(t, e),
        (n = e.clientWidth),
        b(e);
    }
    return +n || 0;
  }
  function p(t, n) {
    return t ? t.querySelector(n.split(" ")[0]) : null;
  }
  function g(t, n) {
    return v(t, n)[0];
  }
  function v(t, n) {
    return t
      ? r(t.children).filter((t) => P(t, n.split(" ")[0]) || t.tagName === n)
      : [];
  }
  function m(t, n) {
    const e = document.createElement(t);
    return o(n, (t, n) => C(e, n, t)), e;
  }
  function y(t) {
    const n = m("div", {});
    return (n.innerHTML = t), n.firstChild;
  }
  function b(t) {
    u(t).forEach((t) => {
      if (t) {
        const n = t.parentElement;
        n && n.removeChild(t);
      }
    });
  }
  function w(t, n) {
    t && t.appendChild(n);
  }
  function x(t, n) {
    if (t && n) {
      const e = n.parentElement;
      e && e.insertBefore(t, n);
    }
  }
  function E(t, n) {
    t &&
      o(n, (n, e) => {
        n !== null && (t.style[e] = n);
      });
  }
  function _(t, n, e) {
    t &&
      u(n).forEach((n) => {
        n && t.classList[e ? "remove" : "add"](n);
      });
  }
  function k(t, n) {
    _(t, n, !1);
  }
  function S(t, n) {
    _(t, n, !0);
  }
  function P(t, n) {
    return !!t && t.classList.contains(n);
  }
  function C(t, n, e) {
    t && t.setAttribute(n, e);
  }
  function z(t, n) {
    return t ? t.getAttribute(n) : "";
  }
  function I(t, n) {
    u(n).forEach((n) => {
      u(t).forEach((t) => t && t.removeAttribute(n));
    });
  }
  function M(t) {
    return t.getBoundingClientRect();
  }
  const T = "slide";
  const A = "loop";
  const O = "fade";
  const L = function (t, n) {
    let e;
    let i;
    return {
      mount() {
        (e = n.Elements.list),
          t.on(
            "transitionend",
            (t) => {
              t.target === e && i && i();
            },
            e
          );
      },
      start(o, r, s, a, u) {
        const c = t.options;
        const d = n.Controller.edgeIndex;
        let f = c.speed;
        (i = u),
          t.is(T) &&
            ((s === 0 && r >= d) || (s >= d && r === 0)) &&
            (f = c.rewindSpeed || f),
          E(e, {
            transition: `transform ${f}ms ${c.easing}`,
            transform: `translate(${a.x}px,${a.y}px)`,
          });
      },
    };
  };
  const W = function (t, n) {
    function e(e) {
      const i = t.options;
      E(n.Elements.slides[e], {
        transition: `opacity ${i.speed}ms ${i.easing}`,
      });
    }
    return {
      mount() {
        e(t.index);
      },
      start(t, i, o, r, s) {
        const a = n.Elements.track;
        E(a, { height: f(a.clientHeight) }),
          e(i),
          setTimeout(() => {
            s(), E(a, { height: "" });
          });
      },
    };
  };
  function H(t) {
    console.error(`[SPLIDE] ${t}`);
  }
  function j(t, n) {
    if (!t) throw new Error(n);
  }
  const q = "splide";
  const D = {
    active: "is-active",
    visible: "is-visible",
    loading: "is-loading",
  };
  const N = {
    type: "slide",
    rewind: !1,
    speed: 400,
    rewindSpeed: 0,
    waitForTransition: !0,
    width: 0,
    height: 0,
    fixedWidth: 0,
    fixedHeight: 0,
    heightRatio: 0,
    autoWidth: !1,
    autoHeight: !1,
    perPage: 1,
    perMove: 0,
    clones: 0,
    start: 0,
    focus: !1,
    gap: 0,
    padding: 0,
    arrows: !0,
    arrowPath: "",
    pagination: !0,
    autoplay: !1,
    interval: 5e3,
    pauseOnHover: !0,
    pauseOnFocus: !0,
    resetProgress: !0,
    lazyLoad: !1,
    preloadPages: 1,
    easing: "cubic-bezier(.42,.65,.27,.99)",
    keyboard: "global",
    drag: !0,
    dragAngleThreshold: 30,
    swipeDistanceThreshold: 150,
    flickVelocityThreshold: 0.6,
    flickPower: 600,
    flickMaxPages: 1,
    direction: "ltr",
    cover: !1,
    accessibility: !0,
    slideFocus: !0,
    isNavigation: !1,
    trimSpace: !0,
    updateOnMove: !1,
    throttle: 100,
    destroy: !1,
    breakpoints: !1,
    classes: {
      root: q,
      slider: `${q}__slider`,
      track: `${q}__track`,
      list: `${q}__list`,
      slide: `${q}__slide`,
      container: `${q}__slide__container`,
      arrows: `${q}__arrows`,
      arrow: `${q}__arrow`,
      prev: `${q}__arrow--prev`,
      next: `${q}__arrow--next`,
      pagination: `${q}__pagination`,
      page: `${q}__pagination__page`,
      clone: `${q}__slide--clone`,
      progress: `${q}__progress`,
      bar: `${q}__progress__bar`,
      autoplay: `${q}__autoplay`,
      play: `${q}__play`,
      pause: `${q}__pause`,
      spinner: `${q}__spinner`,
      sr: `${q}__sr`,
    },
    i18n: {
      prev: "Previous slide",
      next: "Next slide",
      first: "Go to first slide",
      last: "Go to last slide",
      slideX: "Go to slide %s",
      pageX: "Go to page %s",
      play: "Start autoplay",
      pause: "Pause autoplay",
    },
  };
  var R = 1;
  var B = 2;
  var F = 3;
  var G = 4;
  var X = 5;
  function V(t, n) {
    for (let e = 0; e < n.length; e++) {
      const i = n[e];
      (i.enumerable = i.enumerable || !1),
        (i.configurable = !0),
        "value" in i && (i.writable = !0),
        Object.defineProperty(t, i.key, i);
    }
  }
  const U = (function () {
    function t(t, e, i) {
      let o;
      void 0 === e && (e = {}),
        void 0 === i && (i = {}),
        (this.root = t instanceof Element ? t : document.querySelector(t)),
        j(this.root, "An invalid element/selector was given."),
        (this.Components = null),
        (this.Event = (function () {
          let t = [];
          function n(t) {
            t.elm && t.elm.removeEventListener(t.event, t.handler, t.options);
          }
          return {
            on(n, e, i, o) {
              void 0 === i && (i = null),
                void 0 === o && (o = {}),
                n.split(" ").forEach((n) => {
                  i && i.addEventListener(n, e, o),
                    t.push({
                      event: n,
                      handler: e,
                      elm: i,
                      options: o,
                    });
                });
            },
            off(e, i) {
              void 0 === i && (i = null),
                e.split(" ").forEach((e) => {
                  t = t.filter(
                    (t) => !t || t.event !== e || t.elm !== i || (n(t), !1)
                  );
                });
            },
            emit(n) {
              for (
                var e = arguments.length,
                  i = new Array(e > 1 ? e - 1 : 0),
                  o = 1;
                o < e;
                o++
              )
                i[o - 1] = arguments[o];
              t.forEach((t) => {
                t.elm || t.event.split(".")[0] !== n || t.handler.apply(t, i);
              });
            },
            destroy() {
              t.forEach(n), (t = []);
            },
          };
        })()),
        (this.State =
          ((o = R),
          {
            set(t) {
              o = t;
            },
            is(t) {
              return t === o;
            },
          })),
        (this.STATES = n),
        (this._o = a(N, e)),
        (this._i = 0),
        (this._c = i),
        (this._e = {}),
        (this._t = null);
    }
    let e;
    let i;
    let s;
    const u = t.prototype;
    return (
      (u.mount = function (t, n) {
        const e = this;
        void 0 === t && (t = this._e),
          void 0 === n && (n = this._t),
          this.State.set(R),
          (this._e = t),
          (this._t = n),
          (this.Components = (function (t, n, e) {
            const i = {};
            return (
              o(n, (n, e) => {
                i[e] = n(t, i, e.toLowerCase());
              }),
              e || (e = t.is(O) ? W : L),
              (i.Transition = e(t, i)),
              i
            );
          })(this, a(this._c, t), n));
        try {
          o(this.Components, (t, n) => {
            const i = t.required;
            void 0 === i || i ? t.mount && t.mount() : delete e.Components[n];
          });
        } catch (t) {
          return void H(t.message);
        }
        const i = this.State;
        return (
          i.set(B),
          o(this.Components, (t) => {
            t.mounted && t.mounted();
          }),
          this.emit("mounted"),
          i.set(F),
          this.emit("ready"),
          E(this.root, { visibility: "visible" }),
          this.on("move drag", () => i.set(G)).on("moved dragged", () =>
            i.set(F)
          ),
          this
        );
      }),
      (u.sync = function (t) {
        return (this.sibling = t), this;
      }),
      (u.on = function (t, n, e, i) {
        return (
          void 0 === e && (e = null),
          void 0 === i && (i = {}),
          this.Event.on(t, n, e, i),
          this
        );
      }),
      (u.off = function (t, n) {
        return void 0 === n && (n = null), this.Event.off(t, n), this;
      }),
      (u.emit = function (t) {
        for (
          var n, e = arguments.length, i = new Array(e > 1 ? e - 1 : 0), o = 1;
          o < e;
          o++
        )
          i[o - 1] = arguments[o];
        return (n = this.Event).emit.apply(n, [t].concat(i)), this;
      }),
      (u.go = function (t, n) {
        return (
          void 0 === n && (n = this.options.waitForTransition),
          (this.State.is(F) || (this.State.is(G) && !n)) &&
            this.Components.Controller.go(t, !1),
          this
        );
      }),
      (u.is = function (t) {
        return t === this._o.type;
      }),
      (u.add = function (t, n) {
        return (
          void 0 === n && (n = -1),
          this.Components.Elements.add(t, n, this.refresh.bind(this)),
          this
        );
      }),
      (u.remove = function (t) {
        return this.Components.Elements.remove(t), this.refresh(), this;
      }),
      (u.refresh = function () {
        return this.emit("refresh:before").emit("refresh").emit("resize"), this;
      }),
      (u.destroy = function (t) {
        const n = this;
        if ((void 0 === t && (t = !0), !this.State.is(R)))
          return (
            r(this.Components)
              .reverse()
              .forEach((n) => {
                n.destroy && n.destroy(t);
              }),
            this.emit("destroy", t),
            this.Event.destroy(),
            this.State.set(X),
            this
          );
        this.on("ready", () => n.destroy(t));
      }),
      (e = t),
      (i = [
        {
          key: "index",
          get() {
            return this._i;
          },
          set(t) {
            this._i = parseInt(t);
          },
        },
        {
          key: "length",
          get() {
            return this.Components.Elements.length;
          },
        },
        {
          key: "options",
          get() {
            return this._o;
          },
          set(t) {
            const n = this.State.is(R);
            n || this.emit("update"),
              (this._o = a(this._o, t)),
              n || this.emit("updated", this._o);
          },
        },
        {
          key: "classes",
          get() {
            return this._o.classes;
          },
        },
        {
          key: "i18n",
          get() {
            return this._o.i18n;
          },
        },
      ]) && V(e.prototype, i),
      s && V(e, s),
      t
    );
  })();
  const Y = function (t) {
    const n = z(t.root, "data-splide");
    if (n)
      try {
        t.options = JSON.parse(n);
      } catch (t) {
        H(t.message);
      }
    return {
      mount() {
        t.State.is(R) && (t.index = t.options.start);
      },
    };
  };
  const J = "rtl";
  const K = "ttb";
  const Q = "update.slide";
  const Z = function (t, n) {
    const e = t.root;
    const i = t.classes;
    let s = [];
    if (!e.id) {
      window.splide = window.splide || {};
      let a = window.splide.uid || 0;
      (window.splide.uid = ++a), (e.id = `splide${l(a)}`);
    }
    var u = {
      mount() {
        const n = this;
        this.init(),
          t
            .on("refresh", () => {
              n.destroy(), n.init();
            })
            .on("updated", () => {
              S(e, c()), k(e, c());
            });
      },
      destroy() {
        s.forEach((t) => {
          t.destroy();
        }),
          (s = []),
          S(e, c());
      },
      init() {
        const t = this;
        !(function () {
          (u.slider = g(e, i.slider)),
            (u.track = p(e, `.${i.track}`)),
            (u.list = g(u.track, i.list)),
            j(u.track && u.list, "Track or list was not found."),
            (u.slides = v(u.list, i.slide));
          const t = d(i.arrows);
          u.arrows = { prev: p(t, `.${i.prev}`), next: p(t, `.${i.next}`) };
          const n = d(i.autoplay);
          (u.bar = p(d(i.progress), `.${i.bar}`)),
            (u.play = p(n, `.${i.play}`)),
            (u.pause = p(n, `.${i.pause}`)),
            (u.track.id = u.track.id || `${e.id}-track`),
            (u.list.id = u.list.id || `${e.id}-list`);
        })(),
          k(e, c()),
          this.slides.forEach((n, e) => {
            t.register(n, e, -1);
          });
      },
      register(n, e, i) {
        const o = (function (t, n, e, i) {
          const o = t.options.updateOnMove;
          const s = `ready.slide updated.slide resized.slide moved.slide${
            o ? " move.slide" : ""
          }`;
          const a = {
            slide: i,
            index: n,
            realIndex: e,
            container: g(i, t.classes.container),
            isClone: e > -1,
            mount() {
              const r = this;
              this.isClone || (i.id = `${t.root.id}-slide${l(n + 1)}`),
                t
                  .on(s, () => r.update())
                  .on(Q, c)
                  .on("click", () => t.emit("click", r), i),
                o &&
                  t.on("move.slide", (t) => {
                    t === e && u(!0, !1);
                  }),
                E(i, { display: "" }),
                (this.styles = z(i, "style") || "");
            },
            destroy() {
              t.off(s).off(Q).off("click", i),
                S(i, r(D)),
                c(),
                I(this.container, "style");
            },
            update() {
              u(this.isActive(), !1), u(this.isVisible(), !0);
            },
            isActive() {
              return t.index === n;
            },
            isVisible() {
              const n = this.isActive();
              if (t.is(O) || n) return n;
              const e = Math.ceil;
              const o = M(t.Components.Elements.track);
              const r = M(i);
              return t.options.direction === K
                ? o.top <= r.top && r.bottom <= e(o.bottom)
                : o.left <= r.left && r.right <= e(o.right);
            },
            isWithin(e, i) {
              let o = Math.abs(e - n);
              return (
                t.is(T) || this.isClone || (o = Math.min(o, t.length - o)),
                o < i
              );
            },
          };
          function u(n, e) {
            const o = e ? "visible" : "active";
            const r = D[o];
            n
              ? (k(i, r), t.emit(`${o}`, a))
              : P(i, r) && (S(i, r), t.emit(e ? "hidden" : "inactive", a));
          }
          function c() {
            C(i, "style", a.styles);
          }
          return a;
        })(t, e, i, n);
        o.mount(), s.push(o);
      },
      getSlide(t) {
        return s.filter((n) => n.index === t)[0];
      },
      getSlides(t) {
        return t ? s : s.filter((t) => !t.isClone);
      },
      getSlidesByPage(e) {
        const i = n.Controller.toIndex(e);
        const o = t.options;
        const r = !1 !== o.focus ? 1 : o.perPage;
        return s.filter((t) => {
          const n = t.index;
          return i <= n && n < i + r;
        });
      },
      add(t, n, e) {
        if ((typeof t === "string" && (t = y(t)), t instanceof Element)) {
          const i = this.slides[n];
          E(t, { display: "none" }),
            i
              ? (x(t, i), this.slides.splice(n, 0, t))
              : (w(this.list, t), this.slides.push(t)),
            (function (t, n) {
              const e = t.querySelectorAll("img");
              const i = e.length;
              if (i) {
                let r = 0;
                o(e, (t) => {
                  t.onload = t.onerror = function () {
                    ++r === i && n();
                  };
                });
              } else n();
            })(t, () => {
              e && e(t);
            });
        }
      },
      remove(t) {
        b(this.slides.splice(t, 1)[0]);
      },
      each(t) {
        s.forEach(t);
      },
      get length() {
        return this.slides.length;
      },
      get total() {
        return s.length;
      },
    };
    function c() {
      const n = i.root;
      const e = t.options;
      return [
        `${n}--${e.type}`,
        `${n}--${e.direction}`,
        e.drag ? `${n}--draggable` : "",
        e.isNavigation ? `${n}--nav` : "",
        D.active,
      ];
    }
    function d(t) {
      return g(e, t) || g(u.slider, t);
    }
    return u;
  };
  const $ = Math.floor;
  const tt = function (t, n) {
    let e;
    let i;
    var o = {
      mount() {
        (e = t.options),
          (i = t.is(A)),
          t
            .on("move", (n) => {
              t.index = n;
            })
            .on("updated refresh", (n) => {
              (e = n || e), (t.index = c(t.index, 0, o.edgeIndex));
            });
      },
      go(t, e) {
        const i = this.trim(this.parse(t));
        n.Track.go(i, this.rewind(i), e);
      },
      parse(n) {
        let i = t.index;
        const r = String(n).match(/([+\-<>]+)(\d+)?/);
        const s = r ? r[1] : "";
        const a = r ? parseInt(r[2]) : 0;
        switch (s) {
          case "+":
            i += a || 1;
            break;
          case "-":
            i -= a || 1;
            break;
          case ">":
          case "<":
            i = (function (t, n, i) {
              if (t > -1) return o.toIndex(t);
              const r = e.perMove;
              const s = i ? -1 : 1;
              if (r) return n + r * s;
              return o.toIndex(o.toPage(n) + s);
            })(a, i, s === "<");
            break;
          default:
            i = parseInt(n);
        }
        return i;
      },
      toIndex(n) {
        if (r()) return n;
        const i = t.length;
        const o = e.perPage;
        let s = n * o;
        return (
          i - o <= (s -= (this.pageLength * o - i) * $(s / i)) &&
            s < i &&
            (s = i - o),
          s
        );
      },
      toPage(n) {
        if (r()) return n;
        const i = t.length;
        const o = e.perPage;
        return $(i - o <= n && n < i ? (i - 1) / o : n / o);
      },
      trim(t) {
        return (
          i || (t = e.rewind ? this.rewind(t) : c(t, 0, this.edgeIndex)), t
        );
      },
      rewind(t) {
        const n = this.edgeIndex;
        if (i) {
          for (; t > n; ) t -= n + 1;
          for (; t < 0; ) t += n + 1;
        } else t > n ? (t = 0) : t < 0 && (t = n);
        return t;
      },
      isRtl() {
        return e.direction === J;
      },
      get pageLength() {
        const n = t.length;
        return r() ? n : Math.ceil(n / e.perPage);
      },
      get edgeIndex() {
        const n = t.length;
        return n ? (r() || e.isNavigation || i ? n - 1 : n - e.perPage) : 0;
      },
      get prevIndex() {
        let n = t.index - 1;
        return (i || e.rewind) && (n = this.rewind(n)), n > -1 ? n : -1;
      },
      get nextIndex() {
        let n = t.index + 1;
        return (
          (i || e.rewind) && (n = this.rewind(n)),
          (t.index < n && n <= this.edgeIndex) || n === 0 ? n : -1
        );
      },
    };
    function r() {
      return !1 !== e.focus;
    }
    return o;
  };
  const nt = Math.abs;
  const et = function (t, n) {
    let e;
    let i;
    let o;
    const r = t.options.direction === K;
    const s = t.is(O);
    const a = t.options.direction === J;
    let u = !1;
    const d = a ? 1 : -1;
    const f = {
      sign: d,
      mount() {
        (i = n.Elements), (e = n.Layout), (o = i.list);
      },
      mounted() {
        const n = this;
        s ||
          (this.jump(0),
          t.on("mounted resize updated", () => {
            n.jump(t.index);
          }));
      },
      go(e, i, o) {
        const r = h(e);
        const a = t.index;
        (t.State.is(G) && u) ||
          ((u = e !== i),
          o || t.emit("move", i, a, e),
          Math.abs(r - this.position) >= 1 || s
            ? n.Transition.start(e, i, a, this.toCoord(r), () => {
                l(e, i, a, o);
              })
            : e !== a && t.options.trimSpace === "move"
            ? n.Controller.go(e + e - a, o)
            : l(e, i, a, o));
      },
      jump(t) {
        this.translate(h(t));
      },
      translate(t) {
        E(o, { transform: `translate${r ? "Y" : "X"}(${t}px)` });
      },
      cancel() {
        t.is(A) ? this.shift() : this.translate(this.position),
          E(o, { transition: "" });
      },
      shift() {
        let n = nt(this.position);
        const e = nt(this.toPosition(0));
        const i = nt(this.toPosition(t.length));
        const o = i - e;
        n < e ? (n += o) : n > i && (n -= o), this.translate(d * n);
      },
      trim(n) {
        return !t.options.trimSpace || t.is(A)
          ? n
          : c(n, d * (e.totalSize() - e.size - e.gap), 0);
      },
      toIndex(t) {
        const n = this;
        let e = 0;
        let o = 1 / 0;
        return (
          i.getSlides(!0).forEach((i) => {
            const r = i.index;
            const s = nt(n.toPosition(r) - t);
            s < o && ((o = s), (e = r));
          }),
          e
        );
      },
      toCoord(t) {
        return { x: r ? 0 : t, y: r ? t : 0 };
      },
      toPosition(t) {
        const n = e.totalSize(t) - e.slideSize(t) - e.gap;
        return d * (n + this.offset(t));
      },
      offset(n) {
        const i = t.options.focus;
        const o = e.slideSize(n);
        return i === "center"
          ? -(e.size - o) / 2
          : -(parseInt(i) || 0) * (o + e.gap);
      },
      get position() {
        const t = r ? "top" : a ? "right" : "left";
        return M(o)[t] - (M(i.track)[t] - e.padding[t] * d);
      },
    };
    function l(n, e, i, r) {
      E(o, { transition: "" }),
        (u = !1),
        s || f.jump(e),
        r || t.emit("moved", e, i, n);
    }
    function h(t) {
      return f.trim(f.toPosition(t));
    }
    return f;
  };
  const it = function (t, n) {
    let e = [];
    let i = 0;
    const o = n.Elements;
    const r = {
      mount() {
        const n = this;
        t.is(A) &&
          (s(),
          t
            .on("refresh:before", () => {
              n.destroy();
            })
            .on("refresh", s)
            .on("resize", () => {
              i !== a() && (n.destroy(), t.refresh());
            }));
      },
      destroy() {
        b(e), (e = []);
      },
      get clones() {
        return e;
      },
      get length() {
        return e.length;
      },
    };
    function s() {
      r.destroy(),
        (function (t) {
          const n = o.length;
          const i = o.register;
          if (n) {
            for (var r = o.slides; r.length < t; ) r = r.concat(r);
            r.slice(0, t).forEach((t, r) => {
              const s = u(t);
              w(o.list, s), e.push(s), i(s, r + n, r % n);
            }),
              r.slice(-t).forEach((o, s) => {
                const a = u(o);
                x(a, r[0]), e.push(a), i(a, s - t, (n + s - (t % n)) % n);
              });
          }
        })((i = a()));
    }
    function a() {
      const n = t.options;
      if (n.clones) return n.clones;
      let e = n.autoWidth || n.autoHeight ? o.length : n.perPage;
      const i = n.direction === K ? "Height" : "Width";
      const r = h(t.root, n[`fixed${i}`]);
      return (
        r && (e = Math.ceil(o.track[`client${i}`] / r)),
        e * (n.drag ? n.flickMaxPages + 1 : 1)
      );
    }
    function u(n) {
      const e = n.cloneNode(!0);
      return k(e, t.classes.clone), I(e, "id"), e;
    }
    return r;
  };
  function ot(t, n) {
    let e;
    return function () {
      e ||
        (e = setTimeout(() => {
          t(), (e = null);
        }, n));
    };
  }
  const rt = function (t, n) {
    let e;
    let o;
    const r = n.Elements;
    const s = t.options.direction === K;
    const a =
      ((e = {
        mount() {
          t
            .on(
              "resize load",
              ot(() => {
                t.emit("resize");
              }, t.options.throttle),
              window
            )
            .on("resize", c)
            .on("updated refresh", u),
            u(),
            (this.totalSize = s ? this.totalHeight : this.totalWidth),
            (this.slideSize = s ? this.slideHeight : this.slideWidth);
        },
        destroy() {
          I([r.list, r.track], "style");
        },
        get size() {
          return s ? this.height : this.width;
        },
      }),
      (o = s
        ? (function (t, n) {
            let e;
            let i;
            const o = n.Elements;
            const r = t.root;
            return {
              margin: "marginBottom",
              init() {
                this.resize();
              },
              resize() {
                (i = t.options), (e = o.track), (this.gap = h(r, i.gap));
                const n = i.padding;
                const s = h(r, n.top || n);
                const a = h(r, n.bottom || n);
                (this.padding = { top: s, bottom: a }),
                  E(e, { paddingTop: f(s), paddingBottom: f(a) });
              },
              totalHeight(n) {
                void 0 === n && (n = t.length - 1);
                const e = o.getSlide(n);
                return e ? M(e.slide).bottom - M(o.list).top + this.gap : 0;
              },
              slideWidth() {
                return h(r, i.fixedWidth || this.width);
              },
              slideHeight(t) {
                if (i.autoHeight) {
                  const n = o.getSlide(t);
                  return n ? n.slide.offsetHeight : 0;
                }
                const e =
                  i.fixedHeight ||
                  (this.height + this.gap) / i.perPage - this.gap;
                return h(r, e);
              },
              get width() {
                return e.clientWidth;
              },
              get height() {
                const t = i.height || this.width * i.heightRatio;
                return (
                  j(t, '"height" or "heightRatio" is missing.'),
                  h(r, t) - this.padding.top - this.padding.bottom
                );
              },
            };
          })(t, n)
        : (function (t, n) {
            let e;
            const i = n.Elements;
            const o = t.root;
            let r = t.options;
            return {
              margin: `margin${r.direction === J ? "Left" : "Right"}`,
              height: 0,
              init() {
                this.resize();
              },
              resize() {
                (r = t.options), (e = i.track), (this.gap = h(o, r.gap));
                const n = r.padding;
                const s = h(o, n.left || n);
                const a = h(o, n.right || n);
                (this.padding = { left: s, right: a }),
                  E(e, { paddingLeft: f(s), paddingRight: f(a) });
              },
              totalWidth(n) {
                void 0 === n && (n = t.length - 1);
                const e = i.getSlide(n);
                let o = 0;
                if (e) {
                  const s = M(e.slide);
                  const a = M(i.list);
                  (o = r.direction === J ? a.right - s.left : s.right - a.left),
                    (o += this.gap);
                }
                return o;
              },
              slideWidth(t) {
                if (r.autoWidth) {
                  const n = i.getSlide(t);
                  return n ? n.slide.offsetWidth : 0;
                }
                const e =
                  r.fixedWidth ||
                  (this.width + this.gap) / r.perPage - this.gap;
                return h(o, e);
              },
              slideHeight() {
                const t =
                  r.height || r.fixedHeight || this.width * r.heightRatio;
                return h(o, t);
              },
              get width() {
                return e.clientWidth - this.padding.left - this.padding.right;
              },
            };
          })(t, n)),
      i(o).forEach((t) => {
        e[t] ||
          Object.defineProperty(e, t, Object.getOwnPropertyDescriptor(o, t));
      }),
      e);
    function u() {
      a.init(),
        E(t.root, { maxWidth: f(t.options.width) }),
        r.each((t) => {
          t.slide.style[a.margin] = f(a.gap);
        }),
        c();
    }
    function c() {
      const n = t.options;
      a.resize(), E(r.track, { height: f(a.height) });
      const e = n.autoHeight ? null : f(a.slideHeight());
      r.each((t) => {
        E(t.container, { height: e }),
          E(t.slide, {
            width: n.autoWidth ? null : f(a.slideWidth(t.index)),
            height: t.container ? null : e,
          });
      }),
        t.emit("resized");
    }
    return a;
  };
  const st = Math.abs;
  const at = function (t, n) {
    let e;
    let i;
    let r;
    let s;
    const a = n.Track;
    const u = n.Controller;
    const d = t.options.direction === K;
    const f = d ? "y" : "x";
    const l = {
      disabled: !1,
      mount() {
        const e = this;
        const i = n.Elements;
        const r = i.track;
        t.on("touchstart mousedown", h, r)
          .on("touchmove mousemove", g, r, { passive: !1 })
          .on("touchend touchcancel mouseleave mouseup dragend", v, r)
          .on("mounted refresh", () => {
            o(i.list.querySelectorAll("img, a"), (n) => {
              t.off("dragstart", n).on(
                "dragstart",
                (t) => {
                  t.preventDefault();
                },
                n,
                { passive: !1 }
              );
            });
          })
          .on("mounted updated", () => {
            e.disabled = !t.options.drag;
          });
      },
    };
    function h(t) {
      l.disabled || s || p(t);
    }
    function p(t) {
      (e = a.toCoord(a.position)), (i = m(t, {})), (r = i);
    }
    function g(n) {
      if (i)
        if (((r = m(n, i)), s)) {
          if ((n.cancelable && n.preventDefault(), !t.is(O))) {
            const o = e[f] + r.offset[f];
            a.translate(
              (function (n) {
                if (t.is(T)) {
                  const e = a.sign;
                  const i = e * a.trim(a.toPosition(0));
                  const o = e * a.trim(a.toPosition(u.edgeIndex));
                  (n *= e) < i
                    ? (n = i - 7 * Math.log(i - n))
                    : n > o && (n = o + 7 * Math.log(n - o)),
                    (n *= e);
                }
                return n;
              })(o)
            );
          }
        } else
          (function (n) {
            const e = n.offset;
            if (t.State.is(G) && t.options.waitForTransition) return !1;
            let i = (180 * Math.atan(st(e.y) / st(e.x))) / Math.PI;
            d && (i = 90 - i);
            return i < t.options.dragAngleThreshold;
          })(r) && (t.emit("drag", i), (s = !0), a.cancel(), p(n));
    }
    function v() {
      (i = null),
        s &&
          (t.emit("dragged", r),
          (function (e) {
            const i = e.velocity[f];
            const o = st(i);
            if (o > 0) {
              const r = t.options;
              const s = t.index;
              const d = i < 0 ? -1 : 1;
              let l = s;
              if (!t.is(O)) {
                let h = a.position;
                o > r.flickVelocityThreshold &&
                  st(e.offset[f]) < r.swipeDistanceThreshold &&
                  (h +=
                    d *
                    Math.min(
                      o * r.flickPower,
                      n.Layout.size * (r.flickMaxPages || 1)
                    )),
                  (l = a.toIndex(h));
              }
              l === s && o > 0.1 && (l = s + d * a.sign),
                t.is(T) && (l = c(l, 0, u.edgeIndex)),
                u.go(l, r.isNavigation);
            }
          })(r),
          (s = !1));
    }
    function m(t, n) {
      const e = t.timeStamp;
      const i = t.touches;
      const o = i ? i[0] : t;
      const r = o.clientX;
      const s = o.clientY;
      const a = n.to || {};
      const u = a.x;
      const c = void 0 === u ? r : u;
      const d = a.y;
      const f = { x: r - c, y: s - (void 0 === d ? s : d) };
      const l = e - (n.time || 0);
      return {
        to: { x: r, y: s },
        offset: f,
        time: e,
        velocity: { x: f.x / l, y: f.y / l },
      };
    }
    return l;
  };
  const ut = function (t, n) {
    let e = !1;
    function i(t) {
      e &&
        (t.preventDefault(), t.stopPropagation(), t.stopImmediatePropagation());
    }
    return {
      required: t.options.drag,
      mount() {
        t.on("click", i, n.Elements.track, { capture: !0 })
          .on("drag", () => {
            e = !0;
          })
          .on("dragged", () => {
            setTimeout(() => {
              e = !1;
            });
          });
      },
    };
  };
  const ct = 1;
  const dt = 2;
  const ft = 3;
  const lt = function (t, n, e) {
    let i;
    let o;
    let r;
    const s = t.classes;
    const a = t.root;
    const u = n.Elements;
    function c() {
      const r = n.Controller;
      const s = r.prevIndex;
      const a = r.nextIndex;
      const u = t.length > t.options.perPage || t.is(A);
      (i.disabled = s < 0 || !u),
        (o.disabled = a < 0 || !u),
        t.emit(`${e}:updated`, i, o, s, a);
    }
    function d(n) {
      return y(
        `<button class="${s.arrow} ${
          n ? s.prev : s.next
        }" type="button"><svg xmlns="http://www.w3.org/2000/svg"\tviewBox="0 0 40 40"\twidth="40"\theight="40"><path d="${
          t.options.arrowPath ||
          "m15.5 0.932-4.3 4.38 14.5 14.6-14.5 14.5 4.3 4.4 14.6-14.6 4.4-4.3-4.4-4.4-14.6-14.6z"
        }" />`
      );
    }
    return {
      required: t.options.arrows,
      mount() {
        (i = u.arrows.prev),
          (o = u.arrows.next),
          (i && o) ||
            !t.options.arrows ||
            ((i = d(!0)),
            (o = d(!1)),
            (r = !0),
            (function () {
              const n = m("div", { class: s.arrows });
              w(n, i), w(n, o);
              const e = u.slider;
              const r = t.options.arrows === "slider" && e ? e : a;
              x(n, r.firstElementChild);
            })()),
          i &&
            o &&
            t
              .on(
                "click",
                () => {
                  t.go("<");
                },
                i
              )
              .on(
                "click",
                () => {
                  t.go(">");
                },
                o
              )
              .on("mounted move updated refresh", c),
          (this.arrows = { prev: i, next: o });
      },
      mounted() {
        t.emit(`${e}:mounted`, i, o);
      },
      destroy() {
        I([i, o], "disabled"), r && b(i.parentElement);
      },
    };
  };
  const ht = "move.page";
  const pt = "updated.page refresh.page";
  const gt = function (t, n, e) {
    let i = {};
    const o = n.Elements;
    var r = {
      mount() {
        const n = t.options.pagination;
        if (n) {
          i = (function () {
            const n = t.options;
            const e = t.classes;
            const i = m("ul", { class: e.pagination });
            const r = o
              .getSlides(!1)
              .filter((t) => !1 !== n.focus || t.index % n.perPage == 0)
              .map((n, r) => {
                const s = m("li", {});
                const a = m("button", { class: e.page, type: "button" });
                return (
                  w(s, a),
                  w(i, s),
                  t.on(
                    "click",
                    () => {
                      t.go(`>${r}`);
                    },
                    a
                  ),
                  {
                    li: s,
                    button: a,
                    page: r,
                    Slides: o.getSlidesByPage(r),
                  }
                );
              });
            return { list: i, items: r };
          })();
          const e = o.slider;
          w(n === "slider" && e ? e : t.root, i.list), t.on(ht, s);
        }
        t.off(pt).on(pt, () => {
          r.destroy(), t.options.pagination && (r.mount(), r.mounted());
        });
      },
      mounted() {
        if (t.options.pagination) {
          const n = t.index;
          t.emit(`${e}:mounted`, i, this.getItem(n)), s(n, -1);
        }
      },
      destroy() {
        b(i.list),
          i.items &&
            i.items.forEach((n) => {
              t.off("click", n.button);
            }),
          t.off(ht),
          (i = {});
      },
      getItem(t) {
        return i.items[n.Controller.toPage(t)];
      },
      get data() {
        return i;
      },
    };
    function s(n, o) {
      const s = r.getItem(o);
      const a = r.getItem(n);
      const u = D.active;
      s && S(s.button, u), a && k(a.button, u), t.emit(`${e}:updated`, i, s, a);
    }
    return r;
  };
  const vt = "data-splide-lazy";
  const mt = "data-splide-lazy-srcset";
  const yt = "aria-current";
  const bt = "aria-controls";
  const wt = "aria-label";
  const xt = "aria-hidden";
  const Et = "tabindex";
  const _t = {
    ltr: {
      ArrowLeft: "<",
      ArrowRight: ">",
      Left: "<",
      Right: ">",
    },
    rtl: {
      ArrowLeft: ">",
      ArrowRight: "<",
      Left: ">",
      Right: "<",
    },
    ttb: {
      ArrowUp: "<",
      ArrowDown: ">",
      Up: "<",
      Down: ">",
    },
  };
  const kt = function (t, n) {
    const e = t.i18n;
    const i = n.Elements;
    const o = [xt, Et, bt, wt, yt, "role"];
    function r(n, e) {
      C(n, xt, !e), t.options.slideFocus && C(n, Et, e ? 0 : -1);
    }
    function s(t, n) {
      const e = i.track.id;
      C(t, bt, e), C(n, bt, e);
    }
    function a(n, i, o, r) {
      const s = t.index;
      const a = o > -1 && s < o ? e.last : e.prev;
      const u = r > -1 && s > r ? e.first : e.next;
      C(n, wt, a), C(i, wt, u);
    }
    function u(n, i) {
      i && C(i.button, yt, !0),
        n.items.forEach((n) => {
          const i = t.options;
          const o = d(
            !1 === i.focus && i.perPage > 1 ? e.pageX : e.slideX,
            n.page + 1
          );
          const r = n.button;
          const s = n.Slides.map((t) => t.slide.id);
          C(r, bt, s.join(" ")), C(r, wt, o);
        });
    }
    function c(t, n, e) {
      n && I(n.button, yt), e && C(e.button, yt, !0);
    }
    function f(t) {
      i.each((n) => {
        const i = n.slide;
        const o = n.realIndex;
        h(i) || C(i, "role", "button");
        const r = o > -1 ? o : n.index;
        const s = d(e.slideX, r + 1);
        const a = t.Components.Elements.getSlide(r);
        C(i, wt, s), a && C(i, bt, a.slide.id);
      });
    }
    function l(t, n) {
      const e = t.slide;
      n ? C(e, yt, !0) : I(e, yt);
    }
    function h(t) {
      return t.tagName === "BUTTON";
    }
    return {
      required: t.options.accessibility,
      mount() {
        t
          .on("visible", (t) => {
            r(t.slide, !0);
          })
          .on("hidden", (t) => {
            r(t.slide, !1);
          })
          .on("arrows:mounted", s)
          .on("arrows:updated", a)
          .on("pagination:mounted", u)
          .on("pagination:updated", c)
          .on("refresh", () => {
            I(n.Clones.clones, o);
          }),
          t.options.isNavigation &&
            t
              .on("navigation:mounted navigation:updated", f)
              .on("active", (t) => {
                l(t, !0);
              })
              .on("inactive", (t) => {
                l(t, !1);
              }),
          ["play", "pause"].forEach((t) => {
            const n = i[t];
            n &&
              (h(n) || C(n, "role", "button"),
              C(n, bt, i.track.id),
              C(n, wt, e[t]));
          });
      },
      destroy() {
        const t = n.Arrows;
        const e = t ? t.arrows : {};
        I(i.slides.concat([e.prev, e.next, i.play, i.pause]), o);
      },
    };
  };
  const St = "move.sync";
  const Pt = "mouseup touchend";
  const Ct = [" ", "Enter", "Spacebar"];
  const zt = {
    Options: Y,
    Breakpoints(t) {
      let n;
      let e;
      const i = t.options.breakpoints;
      const o = ot(s, 50);
      let r = [];
      function s() {
        let o;
        const s = (o = r.filter((t) => t.mql.matches)[0]) ? o.point : -1;
        if (s !== e) {
          e = s;
          const a = t.State;
          const u = i[s] || n;
          const c = u.destroy;
          c
            ? ((t.options = n), t.destroy(c === "completely"))
            : (a.is(X) && t.mount(), (t.options = u));
        }
      }
      return {
        required: i && matchMedia,
        mount() {
          (r = Object.keys(i)
            .sort((t, n) => +t - +n)
            .map((t) => ({ point: t, mql: matchMedia(`(max-width:${t}px)`) }))),
            this.destroy(!0),
            addEventListener("resize", o),
            (n = t.options),
            s();
        },
        destroy(t) {
          t && removeEventListener("resize", o);
        },
      };
    },
    Controller: tt,
    Elements: Z,
    Track: et,
    Clones: it,
    Layout: rt,
    Drag: at,
    Click: ut,
    Autoplay(t, n, e) {
      let i;
      let o = [];
      const r = n.Elements;
      var s = {
        required: t.options.autoplay,
        mount() {
          const n = t.options;
          r.slides.length > n.perPage &&
            ((i = (function (t, n, e) {
              let i;
              let o;
              let r;
              const s = window.requestAnimationFrame;
              let a = !0;
              const u = function u(c) {
                a ||
                  (i || ((i = c), r && r < 1 && (i -= r * n)),
                  (r = (o = c - i) / n),
                  o >= n && ((i = 0), (r = 1), t()),
                  e && e(r),
                  s(u));
              };
              return {
                pause() {
                  (a = !0), (i = 0);
                },
                play(t) {
                  (i = 0), t && (r = 0), a && ((a = !1), s(u));
                },
              };
            })(
              () => {
                t.go(">");
              },
              n.interval,
              (n) => {
                t.emit(`${e}:playing`, n),
                  r.bar && E(r.bar, { width: `${100 * n}%` });
              }
            )),
            (function () {
              const n = t.options;
              const e = t.sibling;
              const i = [t.root, e ? e.root : null];
              n.pauseOnHover &&
                (a(i, "mouseleave", ct, !0), a(i, "mouseenter", ct, !1));
              n.pauseOnFocus &&
                (a(i, "focusout", dt, !0), a(i, "focusin", dt, !1));
              r.play &&
                t.on(
                  "click",
                  () => {
                    s.play(dt), s.play(ft);
                  },
                  r.play
                );
              r.pause && a([r.pause], "click", ft, !1);
              t.on("move refresh", () => {
                s.play();
              }).on("destroy", () => {
                s.pause();
              });
            })(),
            this.play());
        },
        play(n) {
          void 0 === n && (n = 0),
            (o = o.filter((t) => t !== n)).length ||
              (t.emit(`${e}:play`), i.play(t.options.resetProgress));
        },
        pause(n) {
          void 0 === n && (n = 0),
            i.pause(),
            o.indexOf(n) === -1 && o.push(n),
            o.length === 1 && t.emit(`${e}:pause`);
        },
      };
      function a(n, e, i, o) {
        n.forEach((n) => {
          t.on(
            e,
            () => {
              s[o ? "play" : "pause"](i);
            },
            n
          );
        });
      }
      return s;
    },
    Cover(t, n) {
      function e(t) {
        n.Elements.each((n) => {
          const e = g(n.slide, "IMG") || g(n.container, "IMG");
          e && e.src && i(e, t);
        });
      }
      function i(t, n) {
        E(t.parentElement, {
          background: n ? "" : `center/cover no-repeat url("${t.src}")`,
        }),
          E(t, { display: n ? "" : "none" });
      }
      return {
        required: t.options.cover,
        mount() {
          t.on("lazyload:loaded", (t) => {
            i(t, !1);
          }),
            t.on("mounted updated refresh", () => e(!1));
        },
        destroy() {
          e(!0);
        },
      };
    },
    Arrows: lt,
    Pagination: gt,
    LazyLoad(t, n, e) {
      let i;
      let r;
      const s = t.options;
      const a = s.lazyLoad === "sequential";
      function u() {
        (r = []), (i = 0);
      }
      function c(n) {
        (n = isNaN(n) ? t.index : n),
          (r = r.filter(
            (t) =>
              !t.Slide.isWithin(n, s.perPage * (s.preloadPages + 1)) ||
              (d(t.img, t.Slide), !1)
          ))[0] || t.off(`moved.${e}`);
      }
      function d(n, e) {
        k(e.slide, D.loading);
        const i = m("span", { class: t.classes.spinner });
        w(n.parentElement, i),
          (n.onload = function () {
            l(n, i, e, !1);
          }),
          (n.onerror = function () {
            l(n, i, e, !0);
          }),
          C(n, "srcset", z(n, mt) || ""),
          C(n, "src", z(n, vt) || "");
      }
      function f() {
        if (i < r.length) {
          const t = r[i];
          d(t.img, t.Slide);
        }
        i++;
      }
      function l(n, i, o, r) {
        S(o.slide, D.loading),
          r ||
            (b(i),
            E(n, { display: "" }),
            t.emit(`${e}:loaded`, n).emit("resize")),
          a && f();
      }
      return {
        required: s.lazyLoad,
        mount() {
          t.on("mounted refresh", () => {
            u(),
              n.Elements.each((t) => {
                o(
                  t.slide.querySelectorAll(`[data-splide-lazy], [${mt}]`),
                  (n) => {
                    n.src ||
                      n.srcset ||
                      (r.push({ img: n, Slide: t }), E(n, { display: "none" }));
                  }
                );
              }),
              a && f();
          }),
            a || t.on(`mounted refresh moved.${e}`, c);
        },
        destroy: u,
      };
    },
    Keyboard(t) {
      let n;
      return {
        mount() {
          t.on("mounted updated", () => {
            const e = t.options;
            const i = t.root;
            const o = _t[e.direction];
            const r = e.keyboard;
            n && (t.off("keydown", n), I(i, Et)),
              r &&
                (r === "focused" ? ((n = i), C(i, Et, 0)) : (n = document),
                t.on(
                  "keydown",
                  (n) => {
                    o[n.key] && t.go(o[n.key]);
                  },
                  n
                ));
          });
        },
      };
    },
    Sync(t) {
      const n = t.sibling;
      const e = n && n.options.isNavigation;
      function i() {
        t.on(St, (t, e, i) => {
          n.off(St).go(n.is(A) ? i : t, !1), o();
        });
      }
      function o() {
        n.on(St, (n, e, o) => {
          t.off(St).go(t.is(A) ? o : n, !1), i();
        });
      }
      function r() {
        n.Components.Elements.each((n) => {
          const e = n.slide;
          const i = n.index;
          t.off(Pt, e).on(
            Pt,
            (t) => {
              (t.button && t.button !== 0) || s(i);
            },
            e
          ),
            t.off("keyup", e).on(
              "keyup",
              (t) => {
                Ct.indexOf(t.key) > -1 && (t.preventDefault(), s(i));
              },
              e,
              { passive: !1 }
            );
        });
      }
      function s(e) {
        t.State.is(F) && n.go(e);
      }
      return {
        required: !!n,
        mount() {
          i(),
            o(),
            e &&
              (r(),
              t.on("refresh", () => {
                setTimeout(() => {
                  r(), n.emit("navigation:updated", t);
                });
              }));
        },
        mounted() {
          e && n.emit("navigation:mounted", t);
        },
      };
    },
    A11y: kt,
  };
  const It = (function (t) {
    let n;
    let e;
    function i(n, e) {
      return t.call(this, n, e, zt) || this;
    }
    return (
      (e = t),
      ((n = i).prototype = Object.create(e.prototype)),
      (n.prototype.constructor = n),
      (n.__proto__ = e),
      i
    );
  })(U);
  window.Splide = It;
})();

/*! * LazyLoad.jsVersion 17 - https://github.com/verlok/vanilla-lazyload */
!(function (t, n) {
  typeof exports === "object" && typeof module !== "undefined"
    ? (module.exports = n())
    : typeof define === "function" && define.amd
    ? define(n)
    : ((t = t || self).LazyLoad = n());
})(this, () => {
  function t() {
    return (t =
      Object.assign ||
      function (t) {
        for (let n = 1; n < arguments.length; n++) {
          const e = arguments[n];
          for (const i in e)
            Object.prototype.hasOwnProperty.call(e, i) && (t[i] = e[i]);
        }
        return t;
      }).apply(this, arguments);
  }
  const n = typeof window !== "undefined";
  const e =
    (n && !("onscroll" in window)) ||
    (typeof navigator !== "undefined" &&
      /(gle|ing|ro)bot|crawl|spider/i.test(navigator.userAgent));
  const i = n && "IntersectionObserver" in window;
  const o = n && "classList" in document.createElement("p");
  const r = n && window.devicePixelRatio > 1;
  const a = {
    elements_selector: ".preload-img",
    container: e || n ? document : null,
    threshold: 300,
    thresholds: null,
    data_src: "src",
    data_srcset: "srcset",
    data_sizes: "sizes",
    data_bg: "src",
    data_bg_hidpi: "bg-hidpi",
    data_bg_multi: "bg-multi",
    data_bg_multi_hidpi: "bg-multi-hidpi",
    data_poster: "poster",
    class_applied: "applied",
    class_loading: "loading",
    class_loaded: "loaded",
    class_error: "error",
    class_entered: "entered",
    class_exited: "exited",
    unobserve_completed: !0,
    unobserve_entered: !1,
    cancel_on_exit: !0,
    callback_enter: null,
    callback_exit: null,
    callback_applied: null,
    callback_loading: null,
    callback_loaded: null,
    callback_error: null,
    callback_finish: null,
    callback_cancel: null,
    use_native: !1,
  };
  const c = function (n) {
    return { ...a, ...n };
  };
  const s = function (t, n) {
    let e;
    const i = "LazyLoad::Initialized";
    const o = new t(n);
    try {
      e = new CustomEvent(i, { detail: { instance: o } });
    } catch (t) {
      (e = document.createEvent("CustomEvent")).initCustomEvent(i, !1, !1, {
        instance: o,
      });
    }
    window.dispatchEvent(e);
  };
  const l = "loading";
  const u = "loaded";
  const d = "applied";
  const f = "error";
  const _ = "native";
  const g = "data-";
  const v = "ll-status";
  const p = function (t, n) {
    return t.getAttribute(g + n);
  };
  const b = function (t) {
    return p(t, v);
  };
  const h = function (t, n) {
    return (function (t, n, e) {
      const i = "data-ll-status";
      e !== null ? t.setAttribute(i, e) : t.removeAttribute(i);
    })(t, 0, n);
  };
  const m = function (t) {
    return h(t, null);
  };
  const E = function (t) {
    return b(t) === null;
  };
  const y = function (t) {
    return b(t) === _;
  };
  const A = [l, u, d, f];
  const I = function (t, n, e, i) {
    t && (void 0 === i ? (void 0 === e ? t(n) : t(n, e)) : t(n, e, i));
  };
  const L = function (t, n) {
    o ? t.classList.add(n) : (t.className += (t.className ? " " : "") + n);
  };
  const w = function (t, n) {
    o
      ? t.classList.remove(n)
      : (t.className = t.className
          .replace(new RegExp(`(^|\\s+)${n}(\\s+|$)`), " ")
          .replace(/^\s+/, "")
          .replace(/\s+$/, ""));
  };
  const k = function (t) {
    return t.llTempImage;
  };
  const O = function (t, n) {
    if (n) {
      const e = n._observer;
      e && e.unobserve(t);
    }
  };
  const x = function (t, n) {
    t && (t.loadingCount += n);
  };
  const z = function (t, n) {
    t && (t.toLoadCount = n);
  };
  const C = function (t) {
    for (var n, e = [], i = 0; (n = t.children[i]); i += 1)
      n.tagName === "SOURCE" && e.push(n);
    return e;
  };
  const N = function (t, n, e) {
    e && t.setAttribute(n, e);
  };
  const M = function (t, n) {
    t.removeAttribute(n);
  };
  const R = function (t) {
    return !!t.llOriginalAttrs;
  };
  const G = function (t) {
    if (!R(t)) {
      const n = {};
      (n.src = t.getAttribute("src")),
        (n.srcset = t.getAttribute("srcset")),
        (n.sizes = t.getAttribute("sizes")),
        (t.llOriginalAttrs = n);
    }
  };
  const T = function (t) {
    if (R(t)) {
      const n = t.llOriginalAttrs;
      N(t, "src", n.src), N(t, "srcset", n.srcset), N(t, "sizes", n.sizes);
    }
  };
  const j = function (t, n) {
    N(t, "sizes", p(t, n.data_sizes)),
      N(t, "srcset", p(t, n.data_srcset)),
      N(t, "src", p(t, n.data_src));
  };
  const D = function (t) {
    M(t, "src"), M(t, "srcset"), M(t, "sizes");
  };
  const F = function (t, n) {
    const e = t.parentNode;
    e && e.tagName === "PICTURE" && C(e).forEach(n);
  };
  const P = {
    IMG(t, n) {
      F(t, (t) => {
        G(t), j(t, n);
      }),
        G(t),
        j(t, n);
    },
    IFRAME(t, n) {
      N(t, "src", p(t, n.data_src));
    },
    VIDEO(t, n) {
      !(function (t, e) {
        C(t).forEach((t) => {
          N(t, "src", p(t, n.data_src));
        });
      })(t),
        N(t, "poster", p(t, n.data_poster)),
        N(t, "src", p(t, n.data_src)),
        t.load();
    },
  };
  const S = function (t, n) {
    const e = P[t.tagName];
    e && e(t, n);
  };
  const V = function (t, n, e) {
    x(e, 1), L(t, n.class_loading), h(t, l), I(n.callback_loading, t, e);
  };
  const U = ["IMG", "IFRAME", "VIDEO"];
  const $ = function (t, n) {
    !n ||
      (function (t) {
        return t.loadingCount > 0;
      })(n) ||
      (function (t) {
        return t.toLoadCount > 0;
      })(n) ||
      I(t.callback_finish, n);
  };
  const q = function (t, n, e) {
    t.addEventListener(n, e), (t.llEvLisnrs[n] = e);
  };
  const H = function (t, n, e) {
    t.removeEventListener(n, e);
  };
  const B = function (t) {
    return !!t.llEvLisnrs;
  };
  const J = function (t) {
    if (B(t)) {
      const n = t.llEvLisnrs;
      for (const e in n) {
        const i = n[e];
        H(t, e, i);
      }
      delete t.llEvLisnrs;
    }
  };
  const K = function (t, n, e) {
    !(function (t) {
      delete t.llTempImage;
    })(t),
      x(e, -1),
      (function (t) {
        t && (t.toLoadCount -= 1);
      })(e),
      w(t, n.class_loading),
      n.unobserve_completed && O(t, e);
  };
  const Q = function (t, n, e) {
    const i = k(t) || t;
    B(i) ||
      (function (t, n, e) {
        B(t) || (t.llEvLisnrs = {});
        const i = t.tagName === "VIDEO" ? "loadeddata" : "load";
        q(t, i, n), q(t, "error", e);
      })(
        i,
        (o) => {
          !(function (t, n, e, i) {
            const o = y(n);
            K(n, e, i),
              L(n, e.class_loaded),
              h(n, u),
              I(e.callback_loaded, n, i),
              o || $(e, i);
          })(0, t, n, e),
            J(i);
        },
        (o) => {
          !(function (t, n, e, i) {
            const o = y(n);
            K(n, e, i),
              L(n, e.class_error),
              h(n, f),
              I(e.callback_error, n, i),
              o || $(e, i);
          })(0, t, n, e),
            J(i);
        }
      );
  };
  const W = function (t, n, e) {
    !(function (t) {
      t.llTempImage = document.createElement("IMG");
    })(t),
      Q(t, n, e),
      (function (t, n, e) {
        const i = p(t, n.data_bg);
        const o = p(t, n.data_bg_hidpi);
        const a = r && o ? o : i;
        a &&
          ((t.style.backgroundImage = 'url("'.concat(a, '")')),
          k(t).setAttribute("src", a),
          V(t, n, e));
      })(t, n, e),
      (function (t, n, e) {
        const i = p(t, n.data_bg_multi);
        const o = p(t, n.data_bg_multi_hidpi);
        const a = r && o ? o : i;
        a &&
          ((t.style.backgroundImage = a),
          (function (t, n, e) {
            L(t, n.class_applied),
              h(t, d),
              n.unobserve_completed && O(t, n),
              I(n.callback_applied, t, e);
          })(t, n, e));
      })(t, n, e);
  };
  const X = function (t, n, e) {
    !(function (t) {
      return U.indexOf(t.tagName) > -1;
    })(t)
      ? W(t, n, e)
      : (function (t, n, e) {
          Q(t, n, e), S(t, n), V(t, n, e);
        })(t, n, e);
  };
  const Y = ["IMG", "IFRAME"];
  const Z = function (t) {
    return t.use_native && "loading" in HTMLImageElement.prototype;
  };
  const tt = function (t, n, e) {
    t.forEach((t) =>
      (function (t) {
        return t.isIntersecting || t.intersectionRatio > 0;
      })(t)
        ? (function (t, n, e, i) {
            h(t, "entered"),
              L(t, e.class_entered),
              w(t, e.class_exited),
              (function (t, n, e) {
                n.unobserve_entered && O(t, e);
              })(t, e, i),
              I(e.callback_enter, t, n, i),
              (function (t) {
                return A.indexOf(b(t)) >= 0;
              })(t) || X(t, e, i);
          })(t.target, t, n, e)
        : (function (t, n, e, i) {
            E(t) ||
              (L(t, e.class_exited),
              (function (t, n, e, i) {
                e.cancel_on_exit &&
                  (function (t) {
                    return b(t) === l;
                  })(t) &&
                  t.tagName === "IMG" &&
                  (J(t),
                  (function (t) {
                    F(t, (t) => {
                      D(t);
                    }),
                      D(t);
                  })(t),
                  (function (t) {
                    F(t, (t) => {
                      T(t);
                    }),
                      T(t);
                  })(t),
                  w(t, e.class_loading),
                  x(i, -1),
                  m(t),
                  I(e.callback_cancel, t, n, i));
              })(t, n, e, i),
              I(e.callback_exit, t, n, i));
          })(t.target, t, n, e)
    );
  };
  const nt = function (t) {
    return Array.prototype.slice.call(t);
  };
  const et = function (t) {
    return t.container.querySelectorAll(t.elements_selector);
  };
  const it = function (t) {
    return (function (t) {
      return b(t) === f;
    })(t);
  };
  const ot = function (t, n) {
    return (function (t) {
      return nt(t).filter(E);
    })(t || et(n));
  };
  const rt = function (t, e) {
    const o = c(t);
    (this._settings = o),
      (this.loadingCount = 0),
      (function (t, n) {
        i &&
          !Z(t) &&
          (n._observer = new IntersectionObserver(
            (e) => {
              tt(e, t, n);
            },
            (function (t) {
              return {
                root: t.container === document ? null : t.container,
                rootMargin: t.thresholds || `${t.threshold}px`,
              };
            })(t)
          ));
      })(o, this),
      (function (t, e) {
        n &&
          window.addEventListener("online", () => {
            !(function (t, n) {
              let e;
              ((e = et(t)), nt(e).filter(it)).forEach((n) => {
                w(n, t.class_error), m(n);
              }),
                n.update();
            })(t, e);
          });
      })(o, this),
      this.update(e);
  };
  return (
    (rt.prototype = {
      update(t) {
        let n;
        let o;
        const r = this._settings;
        const a = ot(t, r);
        z(this, a.length),
          !e && i
            ? Z(r)
              ? (function (t, n, e) {
                  t.forEach((t) => {
                    Y.indexOf(t.tagName) !== -1 &&
                      (t.setAttribute("loading", "lazy"),
                      (function (t, n, e) {
                        Q(t, n, e), S(t, n), h(t, _);
                      })(t, n, e));
                  }),
                    z(e, 0);
                })(a, r, this)
              : ((o = a),
                (function (t) {
                  t.disconnect();
                })((n = this._observer)),
                (function (t, n) {
                  n.forEach((n) => {
                    t.observe(n);
                  });
                })(n, o))
            : this.loadAll(a);
      },
      destroy() {
        this._observer && this._observer.disconnect(),
          et(this._settings).forEach((t) => {
            delete t.llOriginalAttrs;
          }),
          delete this._observer,
          delete this._settings,
          delete this.loadingCount,
          delete this.toLoadCount;
      },
      loadAll(t) {
        const n = this;
        const e = this._settings;
        ot(t, e).forEach((t) => {
          O(t, n), X(t, e, n);
        });
      },
    }),
    (rt.load = function (t, n) {
      const e = c(n);
      X(t, e);
    }),
    (rt.resetStatus = function (t) {
      m(t);
    }),
    n &&
      (function (t, n) {
        if (n)
          if (n.length) for (var e, i = 0; (e = n[i]); i += 1) s(t, e);
          else s(t, n);
      })(rt, window.lazyLoadOptions),
    rt
  );
});

/* Enabled AJAX Custom SWUP Plugin */
!(function (e, t) {
  typeof exports === "object" && typeof module === "object"
    ? (module.exports = t())
    : typeof define === "function" && define.amd
    ? define([], t)
    : typeof exports === "object"
    ? (exports.Swup = t())
    : (e.Swup = t());
})(window, () =>
  (function (e) {
    const t = {};
    function n(i) {
      if (t[i]) return t[i].exports;
      const r = (t[i] = { i, l: !1, exports: {} });
      return e[i].call(r.exports, r, r.exports, n), (r.l = !0), r.exports;
    }
    return (
      (n.m = e),
      (n.c = t),
      (n.d = function (e, t, i) {
        n.o(e, t) || Object.defineProperty(e, t, { enumerable: !0, get: i });
      }),
      (n.r = function (e) {
        typeof Symbol !== "undefined" &&
          Symbol.toStringTag &&
          Object.defineProperty(e, Symbol.toStringTag, { value: "Module" }),
          Object.defineProperty(e, "__esModule", { value: !0 });
      }),
      (n.t = function (e, t) {
        if ((1 & t && (e = n(e)), 8 & t)) return e;
        if (4 & t && typeof e === "object" && e && e.__esModule) return e;
        const i = Object.create(null);
        if (
          (n.r(i),
          Object.defineProperty(i, "default", { enumerable: !0, value: e }),
          2 & t && typeof e !== "string")
        )
          for (const r in e) n.d(i, r, ((t) => e[t]).bind(null, r));
        return i;
      }),
      (n.n = function (e) {
        const t =
          e && e.__esModule
            ? function () {
                return e.default;
              }
            : function () {
                return e;
              };
        return n.d(t, "a", t), t;
      }),
      (n.o = function (e, t) {
        return Object.prototype.hasOwnProperty.call(e, t);
      }),
      (n.p = ""),
      n((n.s = 2))
    );
  })([
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 }),
        (t.Link =
          t.markSwupElements =
          t.getCurrentUrl =
          t.transitionEnd =
          t.fetch =
          t.getDataFromHtml =
          t.createHistoryRecord =
          t.classify =
            void 0);
      const i = d(n(8));
      const r = d(n(9));
      const o = d(n(10));
      const a = d(n(11));
      const s = d(n(12));
      const u = d(n(13));
      const l = d(n(14));
      const c = d(n(15));
      function d(e) {
        return e && e.__esModule ? e : { default: e };
      }
      (t.classify = i.default),
        (t.createHistoryRecord = r.default),
        (t.getDataFromHtml = o.default),
        (t.fetch = a.default),
        (t.transitionEnd = s.default),
        (t.getCurrentUrl = u.default),
        (t.markSwupElements = l.default),
        (t.Link = c.default);
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 }),
        (t.query = function (e) {
          const t =
            arguments.length > 1 && void 0 !== arguments[1]
              ? arguments[1]
              : document;
          return typeof e !== "string" ? e : t.querySelector(e);
        }),
        (t.queryAll = function (e) {
          const t =
            arguments.length > 1 && void 0 !== arguments[1]
              ? arguments[1]
              : document;
          return typeof e !== "string"
            ? e
            : Array.prototype.slice.call(t.querySelectorAll(e));
        });
    },
    function (e, t, n) {
      let i;
      const r = (i = n(3)) && i.__esModule ? i : { default: i };
      e.exports = r.default;
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 });
      const i =
        Object.assign ||
        function (e) {
          for (let t = 1; t < arguments.length; t++) {
            const n = arguments[t];
            for (const i in n)
              Object.prototype.hasOwnProperty.call(n, i) && (e[i] = n[i]);
          }
          return e;
        };
      const r = (function () {
        function e(e, t) {
          for (let n = 0; n < t.length; n++) {
            const i = t[n];
            (i.enumerable = i.enumerable || !1),
              (i.configurable = !0),
              "value" in i && (i.writable = !0),
              Object.defineProperty(e, i.key, i);
          }
        }
        return function (t, n, i) {
          return n && e(t.prototype, n), i && e(t, i), t;
        };
      })();
      const o = y(n(4));
      const a = y(n(6));
      const s = y(n(7));
      const u = y(n(16));
      const l = y(n(17));
      const c = y(n(18));
      const d = y(n(19));
      const f = y(n(20));
      const h = y(n(21));
      const p = y(n(22));
      const m = n(23);
      const g = n(1);
      const v = n(0);
      function y(e) {
        return e && e.__esModule ? e : { default: e };
      }
      const w = (function () {
        function e(t) {
          !(function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, e);
          const n = {
            animateHistoryBrowsing: !1,
            animationSelector: '[class*="transition-"]',
            linkSelector: `a[href^="${window.location.origin}"]:not([data-no-swup]), a[href^="/"]:not([data-no-swup]), a[href^="#"]:not([data-no-swup])`,
            cache: !0,
            containers: ["#swup"],
            requestHeaders: {
              "X-Requested-With": "swup",
              Accept: "text/html, application/xhtml+xml",
            },
            plugins: [],
            skipPopStateHandling(e) {
              return !(e.state && e.state.source === "swup");
            },
          };
          const r = { ...n, ...t };
          (this._handlers = {
            animationInDone: [],
            animationInStart: [],
            animationOutDone: [],
            animationOutStart: [],
            animationSkipped: [],
            clickLink: [],
            contentReplaced: [],
            disabled: [],
            enabled: [],
            openPageInNewTab: [],
            pageLoaded: [],
            pageRetrievedFromCache: [],
            pageView: [],
            popState: [],
            samePage: [],
            samePageWithHash: [],
            serverError: [],
            transitionStart: [],
            transitionEnd: [],
            willReplaceContent: [],
          }),
            (this.scrollToElement = null),
            (this.preloadPromise = null),
            (this.options = r),
            (this.plugins = []),
            (this.transition = {}),
            (this.delegatedListeners = {}),
            (this.boundPopStateHandler = this.popStateHandler.bind(this)),
            (this.cache = new a.default()),
            (this.cache.swup = this),
            (this.loadPage = s.default),
            (this.renderPage = u.default),
            (this.triggerEvent = l.default),
            (this.on = c.default),
            (this.off = d.default),
            (this.updateTransition = f.default),
            (this.getAnimationPromises = h.default),
            (this.getPageData = p.default),
            (this.log = function () {}),
            (this.use = m.use),
            (this.unuse = m.unuse),
            (this.findPlugin = m.findPlugin),
            this.enable();
        }
        return (
          r(e, [
            {
              key: "enable",
              value() {
                const e = this;
                if (typeof Promise !== "undefined") {
                  (this.delegatedListeners.click = (0, o.default)(
                    document,
                    this.options.linkSelector,
                    "click",
                    this.linkClickHandler.bind(this)
                  )),
                    window.addEventListener(
                      "popstate",
                      this.boundPopStateHandler
                    );
                  const t = (0, v.getDataFromHtml)(
                    document.documentElement.outerHTML,
                    this.options.containers
                  );
                  (t.url = t.responseURL = (0, v.getCurrentUrl)()),
                    this.options.cache && this.cache.cacheUrl(t),
                    (0, v.markSwupElements)(
                      document.documentElement,
                      this.options.containers
                    ),
                    this.options.plugins.forEach((t) => {
                      e.use(t);
                    }),
                    window.history.replaceState(
                      {
                        ...window.history.state,
                        url: window.location.href,
                        random: Math.random(),
                        source: "swup",
                      },
                      document.title,
                      window.location.href
                    ),
                    this.triggerEvent("enabled"),
                    document.documentElement.classList.add("swup-enabled"),
                    this.triggerEvent("pageView");
                } else console.warn("Promise is not supported");
              },
            },
            {
              key: "destroy",
              value() {
                const e = this;
                this.delegatedListeners.click.destroy(),
                  window.removeEventListener(
                    "popstate",
                    this.boundPopStateHandler
                  ),
                  this.cache.empty(),
                  this.options.plugins.forEach((t) => {
                    e.unuse(t);
                  }),
                  (0, g.queryAll)("[data-swup]").forEach((e) => {
                    e.removeAttribute("data-swup");
                  }),
                  this.off(),
                  this.triggerEvent("disabled"),
                  document.documentElement.classList.remove("swup-enabled");
              },
            },
            {
              key: "linkClickHandler",
              value(e) {
                if (e.metaKey || e.ctrlKey || e.shiftKey || e.altKey)
                  this.triggerEvent("openPageInNewTab", e);
                else if (e.button === 0) {
                  this.triggerEvent("clickLink", e), e.preventDefault();
                  const t = new v.Link(e.delegateTarget);
                  if (
                    t.getAddress() == (0, v.getCurrentUrl)() ||
                    t.getAddress() == ""
                  )
                    t.getHash() != ""
                      ? (this.triggerEvent("samePageWithHash", e),
                        document.querySelector(t.getHash()) != null
                          ? history.replaceState(
                              {
                                url: t.getAddress() + t.getHash(),
                                random: Math.random(),
                                source: "swup",
                              },
                              document.title,
                              t.getAddress() + t.getHash()
                            )
                          : console.warn(
                              `Element for offset not found (${t.getHash()})`
                            ))
                      : this.triggerEvent("samePage", e);
                  else {
                    t.getHash() != "" && (this.scrollToElement = t.getHash());
                    const n = e.delegateTarget.getAttribute(
                      "data-swup-transition"
                    );
                    this.loadPage(
                      { url: t.getAddress(), customTransition: n },
                      !1
                    );
                  }
                }
              },
            },
            {
              key: "popStateHandler",
              value(e) {
                if (!this.options.skipPopStateHandling(e)) {
                  const t = new v.Link(
                    e.state ? e.state.url : window.location.pathname
                  );
                  t.getHash() !== ""
                    ? (this.scrollToElement = t.getHash())
                    : e.preventDefault(),
                    this.triggerEvent("popState", e),
                    this.loadPage({ url: t.getAddress() }, e);
                }
              },
            },
          ]),
          e
        );
      })();
      t.default = w;
    },
    function (e, t, n) {
      const i = n(5);
      e.exports = function (e, t, n, r, o) {
        const a = function (e, t, n, r) {
          return function (n) {
            (n.delegateTarget = i(n.target, t)),
              n.delegateTarget && r.call(e, n);
          };
        }.apply(this, arguments);
        return (
          e.addEventListener(n, a, o),
          {
            destroy() {
              e.removeEventListener(n, a, o);
            },
          }
        );
      };
    },
    function (e, t) {
      if (typeof Element !== "undefined" && !Element.prototype.matches) {
        const n = Element.prototype;
        n.matches =
          n.matchesSelector ||
          n.mozMatchesSelector ||
          n.msMatchesSelector ||
          n.oMatchesSelector ||
          n.webkitMatchesSelector;
      }
      e.exports = function (e, t) {
        for (; e && e.nodeType !== 9; ) {
          if (typeof e.matches === "function" && e.matches(t)) return e;
          e = e.parentNode;
        }
      };
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 });
      const i = (function () {
        function e(e, t) {
          for (let n = 0; n < t.length; n++) {
            const i = t[n];
            (i.enumerable = i.enumerable || !1),
              (i.configurable = !0),
              "value" in i && (i.writable = !0),
              Object.defineProperty(e, i.key, i);
          }
        }
        return function (t, n, i) {
          return n && e(t.prototype, n), i && e(t, i), t;
        };
      })();
      const r = (t.Cache = (function () {
        function e() {
          !(function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, e),
            (this.pages = {}),
            (this.last = null);
        }
        return (
          i(e, [
            {
              key: "cacheUrl",
              value(e) {
                e.url in this.pages == 0 && (this.pages[e.url] = e),
                  (this.last = this.pages[e.url]),
                  this.swup.log(
                    `Cache (${Object.keys(this.pages).length})`,
                    this.pages
                  );
              },
            },
            {
              key: "getPage",
              value(e) {
                return this.pages[e];
              },
            },
            {
              key: "getCurrentPage",
              value() {
                return this.getPage(
                  window.location.pathname + window.location.search
                );
              },
            },
            {
              key: "exists",
              value(e) {
                return e in this.pages;
              },
            },
            {
              key: "empty",
              value() {
                (this.pages = {}),
                  (this.last = null),
                  this.swup.log("Cache cleared");
              },
            },
            {
              key: "remove",
              value(e) {
                delete this.pages[e];
              },
            },
          ]),
          e
        );
      })());
      t.default = r;
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 });
      const i =
        Object.assign ||
        function (e) {
          for (let t = 1; t < arguments.length; t++) {
            const n = arguments[t];
            for (const i in n)
              Object.prototype.hasOwnProperty.call(n, i) && (e[i] = n[i]);
          }
          return e;
        };
      const r = n(0);
      t.default = function (e, t) {
        const n = this;
        let o = [];
        let a = void 0;
        this.triggerEvent("transitionStart", t),
          e.customTransition != null
            ? (this.updateTransition(
                window.location.pathname,
                e.url,
                e.customTransition
              ),
              document.documentElement.classList.add(
                `to-${(0, r.classify)(e.customTransition)}`
              ))
            : this.updateTransition(window.location.pathname, e.url),
          !t || this.options.animateHistoryBrowsing
            ? (function () {
                if (
                  (n.triggerEvent("animationOutStart"),
                  document.documentElement.classList.add("is-changing"),
                  document.documentElement.classList.add("is-leaving"),
                  document.documentElement.classList.add("is-animating"),
                  t && document.documentElement.classList.add("is-popstate"),
                  document.documentElement.classList.add(
                    `to-${(0, r.classify)(e.url)}`
                  ),
                  (o = n.getAnimationPromises("out")),
                  Promise.all(o).then(() => {
                    n.triggerEvent("animationOutDone");
                  }),
                  !t)
                ) {
                  let i;
                  (i =
                    n.scrollToElement != null
                      ? e.url + n.scrollToElement
                      : e.url),
                    (0, r.createHistoryRecord)(i);
                }
              })()
            : this.triggerEvent("animationSkipped"),
          this.cache.exists(e.url)
            ? ((a = new Promise((e) => {
                e();
              })),
              this.triggerEvent("pageRetrievedFromCache"))
            : (a =
                this.preloadPromise && this.preloadPromise.route == e.url
                  ? this.preloadPromise
                  : new Promise((t, o) => {
                      (0, r.fetch)(
                        { ...e, headers: n.options.requestHeaders },
                        (i) => {
                          if (i.status === 500)
                            return n.triggerEvent("serverError"), void o(e.url);
                          const r = n.getPageData(i);
                          r != null
                            ? ((r.url = e.url),
                              n.cache.cacheUrl(r),
                              n.triggerEvent("pageLoaded"),
                              t())
                            : o(e.url);
                        }
                      );
                    })),
          document
            .getElementById("preloader")
            .classList.remove("preloader-hide"),
          setTimeout(() => {
            Promise.all(o.concat([a]))
              .then(() => {
                n.renderPage(n.cache.getPage(e.url), t),
                  (n.preloadPromise = null),
                  setTimeout(() => {
                    document
                      .getElementById("preloader")
                      .classList.add("preloader-hide");
                  }, 50);
              })
              .catch((e) => {
                (n.options.skipPopStateHandling = function () {
                  return (window.location = e), !0;
                }),
                  window.history.go(-1);
              }),
              window.scrollTo(0, 0);
          }, 180);
      };
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 }),
        (t.default = function (e) {
          let t = e
            .toString()
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(/\//g, "-")
            .replace(/[^\w\-]+/g, "")
            .replace(/\-\-+/g, "-")
            .replace(/^-+/, "")
            .replace(/-+$/, "");
          return (
            t[0] === "/" && (t = t.splice(1)), t === "" && (t = "homepage"), t
          );
        });
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 }),
        (t.default = function (e) {
          window.history.pushState(
            {
              url: e || window.location.href.split(window.location.hostname)[1],
              random: Math.random(),
              source: "swup",
            },
            document.getElementsByTagName("title")[0].innerText,
            e || window.location.href.split(window.location.hostname)[1]
          );
        });
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 });
      const i =
        typeof Symbol === "function" && typeof Symbol.iterator === "symbol"
          ? function (e) {
              return typeof e;
            }
          : function (e) {
              return e &&
                typeof Symbol === "function" &&
                e.constructor === Symbol &&
                e !== Symbol.prototype
                ? "symbol"
                : typeof e;
            };
      const r = n(1);
      t.default = function (e, t) {
        let n = document.createElement("html");
        n.innerHTML = e;
        for (
          var o = [],
            a = function (e) {
              if (n.querySelector(t[e]) == null) return { v: null };
              (0, r.queryAll)(t[e]).forEach((i, a) => {
                (0, r.queryAll)(t[e], n)[a].setAttribute("data-swup", o.length),
                  o.push((0, r.queryAll)(t[e], n)[a].outerHTML);
              });
            },
            s = 0;
          s < t.length;
          s++
        ) {
          const u = a(s);
          if ((void 0 === u ? "undefined" : i(u)) === "object") return u.v;
        }
        const l = {
          title: n.querySelector("title").innerText,
          pageClass: n.querySelector("body").className,
          originalContent: e,
          blocks: o,
        };
        return (n.innerHTML = ""), (n = null), l;
      };
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 });
      const i =
        Object.assign ||
        function (e) {
          for (let t = 1; t < arguments.length; t++) {
            const n = arguments[t];
            for (const i in n)
              Object.prototype.hasOwnProperty.call(n, i) && (e[i] = n[i]);
          }
          return e;
        };
      t.default = function (e) {
        const t =
          arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
        const n = {
          url: window.location.pathname + window.location.search,
          method: "GET",
          data: null,
          headers: {},
        };
        const r = { ...n, ...e };
        const o = new XMLHttpRequest();
        return (
          (o.onreadystatechange = function () {
            o.readyState === 4 && (o.status, t(o));
          }),
          o.open(r.method, r.url, !0),
          Object.keys(r.headers).forEach((e) => {
            o.setRequestHeader(e, r.headers[e]);
          }),
          o.send(r.data),
          o
        );
      };
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 }),
        (t.default = function () {
          const e = document.createElement("div");
          const t = {
            WebkitTransition: "webkitTransitionEnd",
            MozTransition: "transitionend",
            OTransition: "oTransitionEnd otransitionend",
            transition: "transitionend",
          };
          for (const n in t) if (void 0 !== e.style[n]) return t[n];
          return !1;
        });
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 }),
        (t.default = function () {
          return window.location.pathname + window.location.search;
        });
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 });
      const i = n(1);
      t.default = function (e, t) {
        for (
          var n = 0,
            r = function (r) {
              e.querySelector(t[r]) == null
                ? console.warn(`Element ${t[r]} is not in current page.`)
                : (0, i.queryAll)(t[r]).forEach((o, a) => {
                    (0, i.queryAll)(t[r], e)[a].setAttribute("data-swup", n),
                      n++;
                  });
            },
            o = 0;
          o < t.length;
          o++
        )
          r(o);
      };
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 });
      const i = (function () {
        function e(e, t) {
          for (let n = 0; n < t.length; n++) {
            const i = t[n];
            (i.enumerable = i.enumerable || !1),
              (i.configurable = !0),
              "value" in i && (i.writable = !0),
              Object.defineProperty(e, i.key, i);
          }
        }
        return function (t, n, i) {
          return n && e(t.prototype, n), i && e(t, i), t;
        };
      })();
      const r = (function () {
        function e(t) {
          !(function (e, t) {
            if (!(e instanceof t))
              throw new TypeError("Cannot call a class as a function");
          })(this, e),
            t instanceof Element || t instanceof SVGElement
              ? (this.link = t)
              : ((this.link = document.createElement("a")),
                (this.link.href = t));
        }
        return (
          i(e, [
            {
              key: "getPath",
              value() {
                let e = this.link.pathname;
                return e[0] !== "/" && (e = `/${e}`), e;
              },
            },
            {
              key: "getAddress",
              value() {
                let e = this.link.pathname + this.link.search;
                return (
                  this.link.getAttribute("xlink:href") &&
                    (e = this.link.getAttribute("xlink:href")),
                  e[0] !== "/" && (e = `/${e}`),
                  e
                );
              },
            },
            {
              key: "getHash",
              value() {
                return this.link.hash;
              },
            },
          ]),
          e
        );
      })();
      t.default = r;
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 });
      const i =
        Object.assign ||
        function (e) {
          for (let t = 1; t < arguments.length; t++) {
            const n = arguments[t];
            for (const i in n)
              Object.prototype.hasOwnProperty.call(n, i) && (e[i] = n[i]);
          }
          return e;
        };
      const r = (n(1), n(0));
      t.default = function (e, t) {
        const n = this;
        document.documentElement.classList.remove("is-leaving");
        const o = new r.Link(e.responseURL);
        window.location.pathname !== o.getPath() &&
          (window.history.replaceState(
            { url: o.getPath(), random: Math.random(), source: "swup" },
            document.title,
            o.getPath()
          ),
          this.cache.cacheUrl({ ...e, url: o.getPath() })),
          (t && !this.options.animateHistoryBrowsing) ||
            document.documentElement.classList.add("is-rendering"),
          this.triggerEvent("willReplaceContent", t);
        for (let a = 0; a < e.blocks.length; a++)
          document.body.querySelector(`[data-swup="${a}"]`).outerHTML =
            e.blocks[a];
        if (
          ((document.title = e.title),
          this.triggerEvent("contentReplaced", t),
          this.triggerEvent("pageView", t),
          this.options.cache || this.cache.empty(),
          setTimeout(() => {
            (t && !n.options.animateHistoryBrowsing) ||
              (n.triggerEvent("animationInStart"),
              document.documentElement.classList.remove("is-animating"));
          }, 10),
          !t || this.options.animateHistoryBrowsing)
        ) {
          const s = this.getAnimationPromises("in");
          Promise.all(s).then(() => {
            n.triggerEvent("animationInDone"),
              n.triggerEvent("transitionEnd", t),
              document.documentElement.className.split(" ").forEach((e) => {
                (new RegExp("^to-").test(e) ||
                  e === "is-changing" ||
                  e === "is-rendering" ||
                  e === "is-popstate") &&
                  document.documentElement.classList.remove(e);
              });
          });
        } else this.triggerEvent("transitionEnd", t);
        this.scrollToElement = null;
      };
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 }),
        (t.default = function (e, t) {
          this._handlers[e].forEach((e) => {
            try {
              e(t);
            } catch (e) {
              console.error(e);
            }
          });
          const n = new CustomEvent(`swup:${e}`, { detail: e });
          document.dispatchEvent(n);
        });
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 }),
        (t.default = function (e, t) {
          this._handlers[e]
            ? this._handlers[e].push(t)
            : console.warn(`Unsupported event ${e}.`);
        });
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 }),
        (t.default = function (e, t) {
          const n = this;
          if (e != null)
            if (t != null)
              if (
                this._handlers[e] &&
                this._handlers[e].filter((e) => e === t).length
              ) {
                const i = this._handlers[e].filter((e) => e === t)[0];
                const r = this._handlers[e].indexOf(i);
                r > -1 && this._handlers[e].splice(r, 1);
              } else console.warn(`Handler for event '${e}' no found.`);
            else this._handlers[e] = [];
          else
            Object.keys(this._handlers).forEach((e) => {
              n._handlers[e] = [];
            });
        });
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 }),
        (t.default = function (e, t, n) {
          this.transition = { from: e, to: t, custom: n };
        });
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 });
      const i = n(1);
      const r = n(0);
      t.default = function () {
        const e = [];
        return (
          (0, i.queryAll)(this.options.animationSelector).forEach((t) => {
            const n = new Promise((e) => {
              t.addEventListener((0, r.transitionEnd)(), (n) => {
                t == n.target && e();
              });
            });
            e.push(n);
          }),
          e
        );
      };
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 });
      const i = n(0);
      t.default = function (e) {
        const t = e.responseText;
        const n = (0, i.getDataFromHtml)(t, this.options.containers);
        return n
          ? ((n.responseURL = e.responseURL
              ? e.responseURL
              : window.location.href),
            n)
          : (console.warn(
              "The link you are hovering over does not exist (404 ERROR) Please make sure your link is pointing to a valid location."
            ),
            null);
      };
    },
    function (e, t, n) {
      Object.defineProperty(t, "__esModule", { value: !0 }),
        (t.use = function (e) {
          if (e.isSwupPlugin)
            return (
              this.plugins.push(e),
              (e.swup = this),
              typeof e._beforeMount === "function" && e._beforeMount(),
              e.mount(),
              this.plugins
            );
          console.warn(`Not swup plugin instance ${e}.`);
        }),
        (t.unuse = function (e) {
          let t = void 0;
          if (
            (t =
              typeof e === "string"
                ? this.plugins.find((t) => e === t.name)
                : e)
          ) {
            t.unmount(),
              typeof t._afterUnmount === "function" && t._afterUnmount();
            const n = this.plugins.indexOf(t);
            return this.plugins.splice(n, 1), this.plugins;
          }
          console.warn("No such plugin.");
        }),
        (t.findPlugin = function (e) {
          return this.plugins.find((t) => e === t.name);
        });
    },
  ])
);

/* Enabled AJAX Custom Preload SWUP Function */
(function e(t, r) {
  if (typeof exports === "object" && typeof module === "object")
    module.exports = r();
  else if (typeof define === "function" && define.amd) define([], r);
  else if (typeof exports === "object") exports.SwupPreloadPlugin = r();
  else t.SwupPreloadPlugin = r();
})(window, () =>
  (function (e) {
    const t = {};
    function r(n) {
      if (t[n]) {
        return t[n].exports;
      }
      const o = (t[n] = { i: n, l: false, exports: {} });
      e[n].call(o.exports, o, o.exports, r);
      o.l = true;
      return o.exports;
    }
    r.m = e;
    r.c = t;
    r.d = function (e, t, n) {
      if (!r.o(e, t)) {
        Object.defineProperty(e, t, { enumerable: true, get: n });
      }
    };
    r.r = function (e) {
      if (typeof Symbol !== "undefined" && Symbol.toStringTag) {
        Object.defineProperty(e, Symbol.toStringTag, { value: "Module" });
      }
      Object.defineProperty(e, "__esModule", { value: true });
    };
    r.t = function (e, t) {
      if (t & 1) e = r(e);
      if (t & 8) return e;
      if (t & 4 && typeof e === "object" && e && e.__esModule) return e;
      const n = Object.create(null);
      r.r(n);
      Object.defineProperty(n, "default", { enumerable: true, value: e });
      if (t & 2 && typeof e !== "string")
        for (const o in e) r.d(n, o, ((t) => e[t]).bind(null, o));
      return n;
    };
    r.n = function (e) {
      const t =
        e && e.__esModule
          ? function t() {
              return e.default;
            }
          : function t() {
              return e;
            };
      r.d(t, "a", t);
      return t;
    };
    r.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    };
    r.p = "";
    return r((r.s = 1));
  })([
    function (e, t, r) {
      Object.defineProperty(t, "__esModule", { value: true });
      const n = (t.query = function e(t) {
        const r =
          arguments.length > 1 && arguments[1] !== undefined
            ? arguments[1]
            : document;
        if (typeof t !== "string") {
          return t;
        }
        return r.querySelector(t);
      });
      const o = (t.queryAll = function e(t) {
        const r =
          arguments.length > 1 && arguments[1] !== undefined
            ? arguments[1]
            : document;
        if (typeof t !== "string") {
          return t;
        }
        return Array.prototype.slice.call(r.querySelectorAll(t));
      });
    },
    function (e, t, r) {
      const n = r(2);
      const o = a(n);
      function a(e) {
        return e && e.__esModule ? e : { default: e };
      }
      e.exports = o.default;
    },
    function (e, t, r) {
      Object.defineProperty(t, "__esModule", { value: true });
      const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            n.enumerable = n.enumerable || false;
            n.configurable = true;
            if ("value" in n) n.writable = true;
            Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          if (r) e(t.prototype, r);
          if (n) e(t, n);
          return t;
        };
      })();
      const o = r(3);
      const a = s(o);
      const u = r(4);
      const i = s(u);
      const l = r(0);
      const f = r(6);
      function s(e) {
        return e && e.__esModule ? e : { default: e };
      }
      function c(e, t) {
        if (!(e instanceof t)) {
          throw new TypeError("Cannot call a class as a function");
        }
      }
      function d(e, t) {
        if (!e) {
          throw new ReferenceError(
            "this hasn't been initialised - super() hasn't been called"
          );
        }
        return t && (typeof t === "object" || typeof t === "function") ? t : e;
      }
      function p(e, t) {
        if (typeof t !== "function" && t !== null) {
          throw new TypeError(
            `Super expression must either be null or a function, not ${typeof t}`
          );
        }
        e.prototype = Object.create(t && t.prototype, {
          constructor: {
            value: e,
            enumerable: false,
            writable: true,
            configurable: true,
          },
        });
        if (t)
          Object.setPrototypeOf
            ? Object.setPrototypeOf(e, t)
            : (e.__proto__ = t);
      }
      const v = (function (e) {
        p(t, e);
        function t() {
          let e;
          let r;
          let n;
          let o;
          c(this, t);
          for (var a = arguments.length, u = Array(a), i = 0; i < a; i++) {
            u[i] = arguments[i];
          }
          return (
            (o =
              ((r =
                ((n = d(
                  this,
                  (e = t.__proto__ || Object.getPrototypeOf(t)).call.apply(
                    e,
                    [this].concat(u)
                  )
                )),
                n)),
              (n.name = "PreloadPlugin"),
              (n.onContentReplaced = function () {
                n.swup.preloadPages();
              }),
              (n.onMouseover = function (e) {
                const t = n.swup;
                t.triggerEvent("hoverLink", e);
                const r = new f.Link(e.delegateTarget);
                if (
                  r.getAddress() !== (0, f.getCurrentUrl)() &&
                  !t.cache.exists(r.getAddress()) &&
                  t.preloadPromise == null
                ) {
                  t.preloadPromise = t.preloadPage(r.getAddress());
                  t.preloadPromise.route = r.getAddress();
                  t.preloadPromise.finally(() => {
                    t.preloadPromise = null;
                  });
                }
              }),
              (n.preloadPage = function (e) {
                const t = n.swup;
                const r = new f.Link(e);
                return new Promise((e, n) => {
                  if (
                    r.getAddress() != (0, f.getCurrentUrl)() &&
                    !t.cache.exists(r.getAddress())
                  ) {
                    (0, f.fetch)(
                      {
                        url: r.getAddress(),
                        headers: t.options.requestHeaders,
                      },
                      (o) => {
                        if (o.status === 500) {
                          t.triggerEvent("serverError");
                          n();
                        } else {
                          const a = t.getPageData(o);
                          if (a != null) {
                            a.url = r.getAddress();
                            t.cache.cacheUrl(a, t.options.debugMode);
                            t.triggerEvent("pagePreloaded");
                          } else {
                            n(r.getAddress());
                            return;
                          }
                          e(t.cache.getPage(r.getAddress()));
                        }
                      }
                    );
                  } else {
                    e(t.cache.getPage(r.getAddress()));
                  }
                });
              }),
              (n.preloadPages = function () {
                (0, l.queryAll)("[data-swup-preload]").forEach((e) => {
                  n.swup.preloadPage(e.href);
                });
              }),
              r)),
            d(n, o)
          );
        }
        n(t, [
          {
            key: "mount",
            value: function e() {
              const t = this.swup;
              t._handlers.pagePreloaded = [];
              t._handlers.hoverLink = [];
              t.preloadPage = this.preloadPage;
              t.preloadPages = this.preloadPages;
              t.delegatedListeners.mouseover = (0, i.default)(
                document.body,
                t.options.linkSelector,
                "mouseover",
                this.onMouseover.bind(this)
              );
              t.preloadPages();
              t.on("contentReplaced", this.onContentReplaced);
            },
          },
          {
            key: "unmount",
            value: function e() {
              const t = this.swup;
              t._handlers.pagePreloaded = null;
              t._handlers.hoverLink = null;
              t.preloadPage = null;
              t.preloadPages = null;
              t.delegatedListeners.mouseover.destroy();
              t.off("contentReplaced", this.onContentReplaced);
            },
          },
        ]);
        return t;
      })(a.default);
      t.default = v;
    },
    function (e, t, r) {
      Object.defineProperty(t, "__esModule", { value: true });
      const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            n.enumerable = n.enumerable || false;
            n.configurable = true;
            if ("value" in n) n.writable = true;
            Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          if (r) e(t.prototype, r);
          if (n) e(t, n);
          return t;
        };
      })();
      function o(e, t) {
        if (!(e instanceof t)) {
          throw new TypeError("Cannot call a class as a function");
        }
      }
      const a = (function () {
        function e() {
          o(this, e);
          this.isSwupPlugin = true;
        }
        n(e, [
          { key: "mount", value: function e() {} },
          { key: "unmount", value: function e() {} },
          { key: "_beforeMount", value: function e() {} },
          { key: "_afterUnmount", value: function e() {} },
        ]);
        return e;
      })();
      t.default = a;
    },
    function (e, t, r) {
      const n = r(5);
      function o(e, t, r, n, o) {
        const a = u.apply(this, arguments);
        e.addEventListener(r, a, o);
        return {
          destroy() {
            e.removeEventListener(r, a, o);
          },
        };
      }
      function a(e, t, r, n, a) {
        if (typeof e.addEventListener === "function") {
          return o.apply(null, arguments);
        }
        if (typeof r === "function") {
          return o.bind(null, document).apply(null, arguments);
        }
        if (typeof e === "string") {
          e = document.querySelectorAll(e);
        }
        return Array.prototype.map.call(e, (e) => o(e, t, r, n, a));
      }
      function u(e, t, r, o) {
        return function (r) {
          r.delegateTarget = n(r.target, t);
          if (r.delegateTarget) {
            o.call(e, r);
          }
        };
      }
      e.exports = a;
    },
    function (e, t) {
      const r = 9;
      if (typeof Element !== "undefined" && !Element.prototype.matches) {
        const n = Element.prototype;
        n.matches =
          n.matchesSelector ||
          n.mozMatchesSelector ||
          n.msMatchesSelector ||
          n.oMatchesSelector ||
          n.webkitMatchesSelector;
      }
      function o(e, t) {
        while (e && e.nodeType !== r) {
          if (typeof e.matches === "function" && e.matches(t)) {
            return e;
          }
          e = e.parentNode;
        }
      }
      e.exports = o;
    },
    function (e, t, r) {
      Object.defineProperty(t, "__esModule", { value: true });
      t.Link =
        t.markSwupElements =
        t.getCurrentUrl =
        t.transitionEnd =
        t.fetch =
        t.getDataFromHTML =
        t.createHistoryRecord =
        t.classify =
          undefined;
      const n = r(7);
      const o = b(n);
      const a = r(8);
      const u = b(a);
      const i = r(9);
      const l = b(i);
      const f = r(10);
      const s = b(f);
      const c = r(11);
      const d = b(c);
      const p = r(12);
      const v = b(p);
      const y = r(13);
      const h = b(y);
      const g = r(14);
      const m = b(g);
      function b(e) {
        return e && e.__esModule ? e : { default: e };
      }
      const w = (t.classify = o.default);
      const P = (t.createHistoryRecord = u.default);
      const _ = (t.getDataFromHTML = l.default);
      const k = (t.fetch = s.default);
      const M = (t.transitionEnd = d.default);
      const j = (t.getCurrentUrl = v.default);
      const O = (t.markSwupElements = h.default);
      const E = (t.Link = m.default);
    },
    function (e, t, r) {
      Object.defineProperty(t, "__esModule", { value: true });
      const n = function e(t) {
        let r = t
          .toString()
          .toLowerCase()
          .replace(/\s+/g, "-")
          .replace(/\//g, "-")
          .replace(/[^\w\-]+/g, "")
          .replace(/\-\-+/g, "-")
          .replace(/^-+/, "")
          .replace(/-+$/, "");
        if (r[0] === "/") r = r.splice(1);
        if (r === "") r = "homepage";
        return r;
      };
      t.default = n;
    },
    function (e, t, r) {
      Object.defineProperty(t, "__esModule", { value: true });
      const n = function e(t) {
        window.history.pushState(
          {
            url: t || window.location.href.split(window.location.hostname)[1],
            random: Math.random(),
            source: "swup",
          },
          document.getElementsByTagName("title")[0].innerText,
          t || window.location.href.split(window.location.hostname)[1]
        );
      };
      t.default = n;
    },
    function (e, t, r) {
      Object.defineProperty(t, "__esModule", { value: true });
      const n =
        typeof Symbol === "function" && typeof Symbol.iterator === "symbol"
          ? function (e) {
              return typeof e;
            }
          : function (e) {
              return e &&
                typeof Symbol === "function" &&
                e.constructor === Symbol &&
                e !== Symbol.prototype
                ? "symbol"
                : typeof e;
            };
      const o = r(0);
      const a = function e(t, r) {
        const a = t
          .replace("<body", '<div id="swupBody"')
          .replace("</body>", "</div>");
        let u = document.createElement("div");
        u.innerHTML = a;
        const i = [];
        const l = function e(t) {
          if (u.querySelector(r[t]) == null) {
            return { v: null };
          }
          (0, o.queryAll)(r[t]).forEach((e, n) => {
            (0, o.queryAll)(r[t], u)[n].dataset.swup = i.length;
            i.push((0, o.queryAll)(r[t], u)[n].outerHTML);
          });
        };
        for (let f = 0; f < r.length; f++) {
          const s = l(f);
          if ((typeof s === "undefined" ? "undefined" : n(s)) === "object")
            return s.v;
        }
        const c = {
          title: u.querySelector("title").innerText,
          pageClass: u.querySelector("#swupBody").className,
          originalContent: t,
          blocks: i,
        };
        u.innerHTML = "";
        u = null;
        return c;
      };
      t.default = a;
    },
    function (e, t, r) {
      Object.defineProperty(t, "__esModule", { value: true });
      const n =
        Object.assign ||
        function (e) {
          for (let t = 1; t < arguments.length; t++) {
            const r = arguments[t];
            for (const n in r) {
              if (Object.prototype.hasOwnProperty.call(r, n)) {
                e[n] = r[n];
              }
            }
          }
          return e;
        };
      const o = function e(t) {
        const r =
          arguments.length > 1 && arguments[1] !== undefined
            ? arguments[1]
            : false;
        const o = {
          url: window.location.pathname + window.location.search,
          method: "GET",
          data: null,
          headers: {},
        };
        const a = { ...o, ...t };
        const u = new XMLHttpRequest();
        u.onreadystatechange = function () {
          if (u.readyState === 4) {
            if (u.status !== 500) {
              r(u);
            } else {
              r(u);
            }
          }
        };
        u.open(a.method, a.url, true);
        Object.keys(a.headers).forEach((e) => {
          u.setRequestHeader(e, a.headers[e]);
        });
        u.send(a.data);
        return u;
      };
      t.default = o;
    },
    function (e, t, r) {
      Object.defineProperty(t, "__esModule", { value: true });
      const n = function e() {
        const t = document.createElement("div");
        const r = {
          WebkitTransition: "webkitTransitionEnd",
          MozTransition: "transitionend",
          OTransition: "oTransitionEnd otransitionend",
          transition: "transitionend",
        };
        for (const n in r) {
          if (t.style[n] !== undefined) {
            return r[n];
          }
        }
        return false;
      };
      t.default = n;
    },
    function (e, t, r) {
      Object.defineProperty(t, "__esModule", { value: true });
      const n = function e() {
        return window.location.pathname + window.location.search;
      };
      t.default = n;
    },
    function (e, t, r) {
      Object.defineProperty(t, "__esModule", { value: true });
      const n = r(0);
      const o = function e(t, r) {
        let o = 0;
        const a = function e(a) {
          if (t.querySelector(r[a]) == null) {
            console.warn(`Element ${r[a]} is not in current page.`);
          } else {
            (0, n.queryAll)(r[a]).forEach((e, u) => {
              (0, n.queryAll)(r[a], t)[u].dataset.swup = o;
              o++;
            });
          }
        };
        for (let u = 0; u < r.length; u++) {
          a(u);
        }
      };
      t.default = o;
    },
    function (e, t, r) {
      Object.defineProperty(t, "__esModule", { value: true });
      const n = (function () {
        function e(e, t) {
          for (let r = 0; r < t.length; r++) {
            const n = t[r];
            n.enumerable = n.enumerable || false;
            n.configurable = true;
            if ("value" in n) n.writable = true;
            Object.defineProperty(e, n.key, n);
          }
        }
        return function (t, r, n) {
          if (r) e(t.prototype, r);
          if (n) e(t, n);
          return t;
        };
      })();
      function o(e, t) {
        if (!(e instanceof t)) {
          throw new TypeError("Cannot call a class as a function");
        }
      }
      const a = (function () {
        function e(t) {
          o(this, e);
          if (t instanceof Element || t instanceof SVGElement) {
            this.link = t;
          } else {
            this.link = document.createElement("a");
            this.link.href = t;
          }
        }
        n(e, [
          {
            key: "getPath",
            value: function e() {
              let t = this.link.pathname;
              if (t[0] !== "/") {
                t = `/${t}`;
              }
              return t;
            },
          },
          {
            key: "getAddress",
            value: function e() {
              let t = this.link.pathname + this.link.search;
              if (this.link.getAttribute("xlink:href")) {
                t = this.link.getAttribute("xlink:href");
              }
              if (t[0] !== "/") {
                t = `/${t}`;
              }
              return t;
            },
          },
          {
            key: "getHash",
            value: function e() {
              return this.link.hash;
            },
          },
        ]);
        return e;
      })();
      t.default = a;
    },
  ])
);
