import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import axios from "axios";
import GAuth from "vue3-google-oauth2";
import "./assets/styles/bootstrap.min.css";
import "./assets/fonts/css/fontawesome-all.min.css";
import "./assets/scss/style.scss";
import "bootstrap";
import "./assets/scripts/bootstrap.min.js";
import VueGtag from "vue-gtag";
import timeago from 'vue-timeago3'
import { id } from 'date-fns/locale' // import custom locale
axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;

//end firebase
const app = createApp(App);

// alias
app.config.globalProperties.$filters = {
  truncate(text, length, clamp) {
    clamp = clamp || "...";
    var content = text;
    return content.length > length ? content.slice(0, length) + clamp : content;
  },
  formatPrice(value) {
    let val = value / 1;
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  },
};
const gAuthOptions = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: "email",
  prompt: "consent",
  fetch_basic_profile: true,
};
app.use(GAuth, gAuthOptions);

// define options
const timeagoOptions = {
  converterOptions: {
      includeSeconds: false,
  },
  locale: id,
}

app.use(timeago,  timeagoOptions) // register timeago with options
app
  .use(store)
  .use(router, axios)
  .use(
    VueGtag,
    {
      pageTrackerTemplate(to) {
        return {
          page_location: process.env.VUE_APP_ROOT_API,
          page_path: to.path,
        };
      },
      config: { id: process.env.VUE_APP_MEASUREMENT_ID },
    },
    router
  )

  .mount("#app");

// console.log("testing");
