<template>
  <div class="card rounded-0 bg-6" data-card-height="150">
    <div class="card-top">
      <a
        @click="menuClose"
        href="#"
        class="close-menu float-end me-2 text-center mt-3 icon-40 notch-clear"
        ><i class="fa fa-times color-highlight"></i
      ></a>
    </div>
    <div class="card-bottom">
      <h1 class="color-white ps-3 mb-n1 font-28">PasJob</h1>
      <p class="mb-2 ps-3 font-12 color-white opacity-50">
        Welcome to the Future
      </p>
    </div>
    <div class="card-overlay bg-gradient"></div>
  </div>
  <div class="mt-4"></div>
  <h6 class="menu-divider">Library</h6>
  <div class="list-group list-custom-small list-menu">
    <a id="nav-welcome"> </a>
    <router-link id="nav-homepages" :to="{ name: 'Dashboard' }">
      <i class="fa fa-home gradient-green color-white"></i>
      <span>Dashboard</span>
      <i class="fa fa-angle-right"></i>
    </router-link>
  </div>

  <h6 class="menu-divider mt-5">settings</h6>
  <div class="list-group list-custom-small list-menu">
    <a href="#" @click="menuOpen('menu-colors')">
      <i class="fa fa-brush gradient-highlight color-white"></i>
      <span>Highlights</span>
      <i class="fa fa-angle-right"></i>
    </a>
    <a href="#" data-toggle-theme="" data-trigger-switch="switch-dark-mode">
      <i class="fa fa-moon gradient-dark color-white"></i>
      <span>Dark Mode</span>
      <div class="custom-control small-switch ios-switch">
        <input
          data-toggle-theme
          type="checkbox"
          class="ios-input"
          id="toggle-dark-menu"
        />
        <label class="custom-control-label" for="toggle-dark-menu"></label>
      </div>
    </a>
    <a href="#" @click="signOut">
      <i class="fa fa-sign-out-alt gradient-highlight color-white"></i>
      <span>Sign Out</span>
      <i class="fa fa-angle-right"></i>
    </a>
  </div>
  <h6 class="menu-divider mt-4"></h6>
</template>

<script>
import axios from "axios";
import { dataMenuLoad, menuOpen } from "./index";
export default {
  name: "MenuMain",
  mounted() {
    dataMenuLoad();
  },
  emits: ["close"],
  methods: {
    dataMenuLoad,
    menuClose() {
      this.$emit("close");
    },
    menuOpen,
    signOut() {
      axios.defaults.headers.common["Authorization"] = "";

      this.$store.commit("removeUserInformation");
      this.$router.push("/sign-in");
    },
  },
};
</script>
