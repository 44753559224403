import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import NotFound from "../views/NotFound.vue";

const routes = [
  //{
  //path: "/",
  //name: "StarterPage",
  //component: () => import("../views/job_seeker/Vacancy.vue"),
  //},
  {
    path: "/",
    name: "Home",
    //redirect: "/job-seeker/vacancy"
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: () => import("@/views/authentication/SignIn.vue"),
  },
  {
    path: "/sign-up/confirm/",
    name: "CreateConfirm",
    component: () => import("@/views/authentication/CreateConfirm.vue"),
  },

  {
    path: "/sign-up",
    name: "SignUp",
    component: () => import("@/views/authentication/SignUp.vue"),
  },

  {
    path: "/email-verify/",
    name: "EmailVerify",
    component: () => import("@/views/authentication/EmailVerify.vue"),
  },
  {
    path: "/success",
    name: "Success",
    component: () => import("@/views/authentication/Success.vue"),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/authentication/ResetPassword.vue"),
  },
  {
    path: "/new-password",
    name: "NewPassword",
    component: () => import("@/views/authentication/NewPassword.vue"),
  },
  {
    path: "/article/event",
    name: "Event",
    component: () => import("@/views/Event.vue"),
  },
  {
    path: "/article/list",
    name: "ArticleList",
    component: () => import("@/views/blog/BlogList.vue"),
  },
  {
    path: "/article/detail/:slug",
    name: "ArticleDetail",
    component: () => import("@/views/blog/BlogDetail.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/Dashboard.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/account",
    name: "Account",
    component: () => import("@/views/authentication/Account.vue"),
    meta: {
      requireLogin: true,
    },
  },

  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/term-and-condition",
    name: "Term",
    component: () => import("../views/Term.vue"),
  },
  {
    path: "/invite-friend",
    name: "InviteFriend",
    component: () => import("../views/InviteFriend.vue"),
    meta: {
      requireLogin: true,
    },
  },
  //employer
  {
    path: "/company/detail/:company_id",
    name: "CompanyDetail",
    component: () => import("../views/employer/CompanyDetail.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/company/profile",
    name: "CompanyProfile",
    component: () => import("../views/employer/CompanyProfile.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/company/user-list",
    name: "UserList",
    component: () => import("../views/employer/UserList.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/company/user/:id",
    name: "UserDetail",
    component: () => import("../views/employer/UserDetail.vue"),
    meta: {
      requireLogin: true,
    },
  },
  // *  create Job
  {
    path: "/company/job/add",
    name: "FormJob",
    component: () => import("../views/employer/FormJob.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/company/job/update/:id",
    name: "UpdateJob",
    component: () => import("../views/employer/UpdateJob.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/company/job/:job_type",
    name: "CreateJob",
    component: () => import("../views/employer/CreateJob.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/company/request-talent",
    name: "RequestTalent",
    component: () => import("../views/employer/RequestTalent.vue"),
    meta: {
      requireLogin: true,
    },
  },
  // Apply Job List
  {
    path: "/company/apply/:job_id",
    name: "ApplyJobList",
    component: () => import("../views/employer/ApplyJobList.vue"),
    meta: {
      requireLogin: true,
    },
  },

  {
    path: "/company/apply/:job_id/:user_id",
    name: "DetailApplyJob",
    component: () => import("../views/employer/DetailApplyJob.vue"),
    meta: {
      requireLogin: true,
    },
  },

  {
    path: "/company/:id",
    name: "CompanyJobList",
    component: () => import("../views/employer/CompanyJobList.vue"),
  },

  // Job Seeker
  {
    path: "/job-seeker/profile",
    name: "UserProfile",
    component: () => import("../views/job_seeker/UserProfile.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/job-seeker/cv",
    name: "UploadCV",
    component: () => import("../views/job_seeker/UploadCV.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/job-seeker/skill",
    name: "UserSkill",
    component: () => import("../views/job_seeker/UserSkill.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/job-seeker/experience",
    name: "JobExperience",
    component: () => import("../views/job_seeker/JobExperience.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/job-seeker/experience/add",
    name: "AddExperience",
    component: () => import("../views/job_seeker/AddExperience.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/job-seeker/experience/add/:id",
    name: "EditExperience",
    component: () => import("../views/job_seeker/AddExperience.vue"),
    meta: {
      requireLogin: true,
    },
  },
  //Find Job
  {
    path: "/job/category",
    name: "CategoryList",
    component: () => import("../views/job/CategoryList.vue"),
  },
  {
    path: "/job/category/:level/:parent",
    name: "CategoryJobList",
    component: () => import("../views/job/CategoryJobList.vue"),
    props: true,
  },
  {
    path: "/job-seeker/vacancy",
    name: "Vacancy",
    component: () => import("../views/job_seeker/Vacancy.vue"),
  },
  {
    path: "/job-seeker/project",
    name: "Project",
    component: () => import("../views/job_seeker/Project.vue"),
  },
  {
    path: "/job-seeker/job/filter",
    name: "Filter",
    component: () => import("../views/job_seeker/Filter.vue"),
  },
  {
    path: "/job-seeker/job/:id",
    name: "JobDetail",
    component: () => import("../views/job_seeker/JobDetail.vue"),
  },
  // Applied
  //
  {
    path: "/job-seeker/apply/:job_type",
    name: "Apply",
    component: () => import("../views/job_seeker/Apply.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/job-seeker/bookmark/job/list",
    name: "Bookmark",
    component: () => import("../views/job_seeker/Bookmark.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/poin-saya",
    name: "PoinSaya",
    component: () => import("../views/poin/PoinSaya.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/redeem-point",
    name: "RedeemPoint",
    component: () => import("../views/poin/RedeemPoint.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/redeem-status",
    name: "RedeemStatus",
    component: () => import("../views/poin/RedeemStatus.vue"),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: "/tanya-pasjob",
    name: "TanyaPasjob",
    component: () => import("../views/TanyaPage.vue"),
  },

  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: NotFound,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // store.commit("setSavedPosition", savedPosition);
    if (savedPosition) {
      return savedPosition; // Restore last scroll position
    } else {
      return { top: 0 }; // Scroll to top if no saved position
    }
  }
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requireLogin) &&
    !store.state.isAuthenticated
  ) {
    console.log("redirect to signin");
    next({ name: "SignIn", query: { to: to.path } });
  } else {
    next();
  }
});

export default router;
