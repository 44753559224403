<template>
  <div class="header header-fixed header-logo-center" :class="getClass()">
    <a href="index.html" class="header-title">{{ title }}</a>
    <a @click="goBack" data-back-button class="header-icon header-icon-1"
      ><i class="fas fa-chevron-left"></i
    ></a>

<!--    <a-->
<!--      v-if="shareBtn"-->
<!--      @click="shareJob"-->
<!--      data-menu="menu-main"-->
<!--      class="header-icon header-icon-3"-->
<!--      ><i class="fa fa-copy"></i-->
<!--    ></a>-->

   <a
     v-if="shareBtn"
     @click="bookmark"
     data-menu="menu-main"
     :class="{ 'color-red-dark': bookmarked }"
     class="header-icon header-icon-4"
     >
     <i v-if="!bookmarked" class="far fa-bookmark"></i>
    <i v-if="bookmarked" class="fa fa-bookmark"></i>
   </a>
    <router-link
      v-if="!shareBtn"
      :to="{ name: 'Dashboard' }"
      @click="menuOpen('menu-main')"
      data-menu="menu-main"
      class="header-icon header-icon-4"
      ><i class="fas fa-bars"></i
    ></router-link>
  </div>
  <div
    id="toast-2"
    class="toast toast-tiny toast-top bg-highlight"
    data-bs-delay="1000"
    data-bs-autohide="true"
  >
    <i class="fas fa-lightbulb me-1"></i>
    Link disalin
  </div>
</template>

<script>
import axios from "axios";
import { menuOpen } from "./menu/index.js";
import { Toast } from "bootstrap";
import useClipboard from "vue-clipboard3";
export default {
  name: "Header",
  props: {
    add_class: Array,
    name: String,
    shareBtn: Boolean,
    bookmarked: Boolean,
  },
  emits: ["bookmark"],
  computed: {
    title() {
      return this.name || "PasJob";
    },
  },
  methods: {
    menuOpen,
    goBack() {
      this.$router.go(-1);
    },
    getClass() {
      return this.add_class ? [...this.add_class] : "";
    },

    bookmark() {
      console.log("clicked");
      this.$emit("bookmark");
    },
    async shareJob() {
      const { toClipboard } = useClipboard();

      try {
        var fullPathUrl = axios.defaults.baseURL + this.$route.fullPath;
        await toClipboard(fullPathUrl);
        var notificationToast = document.getElementById("toast-2");
        notificationToast = new Toast(notificationToast);
        notificationToast.show();
        console.log("url : ", axios.defaults.baseURL + this.$route.fullPath);
      } catch (e) {
        console.error(e);
      }

      /*var fullPathUrl = axios.defaults.baseURL + this.$route.fullPath;*/

      /*navigator.clipboard.writeText(fullPathUrl);*/

      /*console.log(notificationToast);*/
      /*clipboard.writeText(fullPathUrl);*/
    },
  },
};
</script>
