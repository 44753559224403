<template>
  <div class="menu-title">
    <p class="color-highlight font-600">Choose your Favorite</p>
    <h1>Highlight</h1>
    <a href="#" class="close-menu" @click="menuClose"
      ><i class="fa fa-times-circle"></i
    ></a>
  </div>
  <div class="divider divider-margins mt-3 mb-2"></div>
  <div class="content mt-0 ms-0 me-0">
    <div class="row mb-0">
      <div class="col-6">
        <div class="list-group list-custom-small list-menu">
          <a href="#" data-change-highlight="blue">
            <i class="gradient-blue color-white"></i>
            <span>Blue</span>
          </a>
          <a href="#" data-change-highlight="red">
            <i class="gradient-red color-white"></i>
            <span>Red</span>
          </a>
          <a href="#" data-change-highlight="orange">
            <i class="gradient-orange color-white"></i>
            <span>Orange</span>
          </a>
          <a href="#" data-change-highlight="green">
            <i class="gradient-green color-white"></i>
            <span>Green</span>
          </a>
          <a href="#" data-change-highlight="yellow">
            <i class="gradient-yellow color-white"></i>
            <span>Yellow</span>
          </a>
        </div>
      </div>
      <div class="col-6">
        <div class="list-group list-custom-small list-menu">
          <a href="#" data-change-highlight="dark">
            <i class="gradient-dark color-white"></i>
            <span>Dark</span>
          </a>
          <a href="#" data-change-highlight="gray">
            <i class="gradient-gray color-white"></i>
            <span>Gray</span>
          </a>
          <a href="#" data-change-highlight="teal">
            <i class="gradient-teal color-white"></i>
            <span>Teal</span>
          </a>
          <a href="#" data-change-highlight="magenta">
            <i class="gradient-magenta color-white"></i>
            <span>Plum</span>
          </a>
          <a href="#" data-change-highlight="brown">
            <i class="gradient-brown color-white"></i>
            <span>Brown</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="divider divider-margins mt-2"></div>
  <a
    href="#"
    @click="menuClose"
    class="
      close-menu
      btn btn-margins btn-m
      font-13
      rounded-s
      shadow-xl
      btn-full
      gradient-highlight
      border-0
      font-700
      text-uppercase
    "
    >Awesome</a
  >
</template>

<script>
import { dataMenuLoad } from "./index.js";
export default {
  name: "MenuColors",
  mounted() {
    dataMenuLoad();
  },
  emits: ["close"],
  methods: {
    menuClose() {
      this.$emit("close");
    },
  },
};
</script>
