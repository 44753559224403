<template>
  <!-- Preloader will be added here-->
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div
    v-if="notifTitle"
    id="notification-01"
    data-dismiss="notification-1"
    data-bs-delay="3000"
    data-bs-autohide="true"
    class="notification notification-ios bg-dark-dark ms-2 me-2 mt-2 rounded-s"
  >
    <span class="notification-icon color-white rounded-s">
      <i class="fa fa-bell"></i>
      <em>Notification</em>
      <i data-dismiss="notification-1" class="fa fa-times-circle"></i>
    </span>
    <h1 class="font-18 color-white mb-n3">{{ notifTitle }}</h1>
    <p class="pt-1">
      {{ notifBody }}
    </p>
  </div>
  <div><router-view v-if="token_valid" /></div>
</template>

<script>
import axios from "axios";
import firebaseMessaging from "./firebase";
import { onMessage, getToken } from "firebase/messaging";
import { Toast } from "bootstrap";
export default {
  data() {
    return {
      isLoading: false,
      token_valid: true,
      notifTitle: "",
      notifBody: "",
    };
  },
  beforeCreate() {
    this.$store.commit("initializeStore");
    const token = this.$store.state.token;

    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    } else {
      axios.defaults.headers.common["Authorization"] = "";
    }
  },
  provide() {
    return {
      isAuthenticated: this.$store.state.isAuthenticated,
      getMasterData: this.getMasterData,
      getApply: this.getApply,
      messaging: firebaseMessaging,
    };
  },
  mounted() {
    /*refrest token*/
    document.title = "pasjob";

    if (this.$store.state.isAuthenticated) {
      this.isLoading = true;
      this.token_valid = false;
      this.getToken();
      if (process.env.NODE_ENV === "production") {
        setInterval(() => {
          this.getToken();
        }, 50000);

        /*token not valid*/
        if (!this.$store.state.token_valid) {
          this.getToken();
        }
      }

      getToken(firebaseMessaging)
        .then((currentToken) => {
          if (currentToken) {
            this.receiveMessage();
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    }
  },
  methods: {
    receiveMessage() {
      try {
        onMessage(firebaseMessaging, (payload) => {
          console.log('messag : ', payload)
          this.notifBody = payload.notification.body;
          this.notifTitle = payload.notification.title;
          var notificationToast = document.getElementById("notification-01");
          notificationToast = new Toast(notificationToast);
          notificationToast.show();
        });
      } catch (e) {
        console.log(e);
      }
    },
    getToken() {
      const accessData = {
        refresh: this.$store.state.refresh,
      };
      if (accessData.refresh.length && !this.$store.state.token_valid) {
        axios
          .post("/api/v1/auth/token/refresh/", accessData)
          .then((response) => {
            const token = response.data.access;
            localStorage.setItem("token", token);
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
            this.token_valid = true;
            this.isLoading = false;
            this.$store.commit("setToken", token);
          })
          .catch((error) => {
            if (error.response) {
              /*when refresh token expired*/
              console.log("response error:", JSON.stringify(error.response));
            } else if (error.message) {
              console.log("error message :", JSON.stringify(error.message));
            }
            this.$store.commit("removeUserInformation");
            this.$router.push("/sign-in");
          });
      }
    },

    async getMasterData() {
      await axios
        .get("/api/v1/employer/master/")
        .then((response) => {
          this.$store.commit("addMasterData", response.data);
          this.isLoading = false;
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "response data : " + JSON.stringify(error.response.data)
            );
          } else if (error.message) {
            console.log("error :" + JSON.stringify(error));
          }
        });
    },

    async getApply(id) {
      await axios
        .get("/api/v1/job-seeker/job/apply/" + id + "/")
        .then((response) => {
          this.$store.commit("addApply", response.data.apply);
        })
        .catch((error) => {
          if (error.response) {
            console.log(
              "response data getApply : " + JSON.stringify(error.response.data)
            );
          } else if (error.message) {
            console.log("error :" + JSON.stringify(error));
          }
        });
    },
  },
};
</script>
<style>
html {
  margin: 0 auto;
  max-width: 480px;
}

#footer-bar,
.header,
.page-title {
  margin: 0 auto;
  max-width: 480px;
}
.custom-form-control {
  background-color: #fff;
  color: #212529;
  border: 1px solid #ced4da;
}
.req-val {
  opacity: 1 !important;
}

div.page-title-clear {
  height: calc(60px + env(safe-area-inset-top) * 0.2) !important;
}
</style>
