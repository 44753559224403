<template>
  <div v-if="default_footer" id="footer-bar" class="footer-bar-6">
    <router-link active-class="active-nav" :to="{ name: 'Vacancy' }"
      ><i class="fa fa-layer-group"></i><span> Vacancy </span></router-link
    >
    <router-link
      :to="{ name: 'StarterPage' }"
      active-class="active-nav"
      class="circle-nav"
      ><i class="fa fa-home"></i><span> Home </span></router-link
    >

    <router-link :to="{ name: 'Project' }" active-class="active-nav"
      ><i class="fa fa-file"></i><span> Project </span></router-link
    >
  </div>

  <div v-if="logged" id="footer-bar" class="footer-bar-6">
    <router-link :to="{ name: 'Vacancy' }" active-class="active-nav"
      ><i class="fa fa-layer-group"></i><span>Lowongan</span></router-link
    >

    <router-link :to="{ name: 'Project' }" active-class="active-nav"
      ><i class="fa fa-file"></i><span>Freelance</span></router-link
    >
    <router-link
      :to="{ name: 'Home' }"
      active-class="active-nav"
      class="circle-nav"
      ><i class="fa fa-home"></i><span>Beranda</span></router-link
    >

    <router-link
      :to="{ name: 'CategoryList' }"
      active-class="active-nav"
      @click="reload"
      ><i class="fa fa-bars"></i><span>Kategori</span></router-link
    >

    <router-link
      :to="urlAccount"
      active-class="active-nav"
    >
      <i class="fas fa-user"></i>
      <span> {{ loginLabel }}</span>
    </router-link>
  </div>
</template>

<script>
import axios from "axios";
import { menuOpen } from "./menu/index.js";
export default {
  name: "Footer",
  data() {
    return {
      loginLabel: "",
      inviteCode: this.$route.query.invite_code,
      urlAccount: {}
    };
  },
  props: {
    logged: Boolean,
    default_footer: Boolean,
  },
  mounted() {
    let isAuthenticated = this.$store.state.isAuthenticated;
    isAuthenticated
      ? (this.loginLabel = "Akun Saya")
      : (this.loginLabel = "Login/Daftar");

    if (isAuthenticated) {
      this.urlAccount = { name: 'Dashboard' }
    } else {
      if (this.inviteCode) {
        this.urlAccount = { name: 'SignIn', query: { invite_code: this.inviteCode } }
      } else {
        this.urlAccount = { name: 'Dashboard' }
      }
    }
  },
  emits: ["reload"],
  methods: {
    menuOpen,
    reload(event) {
      if (event.target.classList.length) {
        this.$emit("reload");
      }
    },
    signOut() {
      axios.defaults.headers.common["Authorization"] = "";
      this.$store.commit("removeUserInformation");
    },
  },
};
</script>
