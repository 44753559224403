/* eslint-disable */
let i;
let e;
let el; // https://www.w3schools.com/js/js_performance.asp

const pwaName = "PasJob"; // Local Storage Names

// Card Extender
const cards = document.getElementsByClassName("card");
function cardExtender() {
  let headerHeight;
  let footerHeight;
  var headerOnPage;
  var headerOnPage = document.querySelectorAll(
    ".header:not(.header-transparent)"
  )[0];
  const footerOnPage = document.querySelectorAll("#footer-bar")[0];

  headerOnPage
    ? (headerHeight = document.querySelectorAll(".header")[0].offsetHeight)
    : (headerHeight = 0);
  footerOnPage
    ? (footerHeight = document.querySelectorAll("#footer-bar")[0].offsetHeight)
    : (footerHeight = 0);

  for (let i = 0; i < cards.length; i++) {
    if (cards[i].getAttribute("data-card-height") === "cover") {
      if (window.matchMedia("(display-mode: fullscreen)").matches) {
        var windowHeight = window.outerHeight;
      }
      if (!window.matchMedia("(display-mode: fullscreen)").matches) {
        var windowHeight = window.innerHeight;
      }
      var coverHeight = `${windowHeight - headerHeight - footerHeight}px`;
    }
    if (cards[i].getAttribute("data-card-height") === "cover-card") {
      var windowHeight = window.outerHeight;
      var coverHeight = `${windowHeight - 275}px`;
      cards[i].style.height = coverHeight;
    }
    if (cards[i].getAttribute("data-card-height") === "cover-full") {
      if (window.matchMedia("(display-mode: fullscreen)").matches) {
        var windowHeight = window.outerHeight;
      }
      if (!window.matchMedia("(display-mode: fullscreen)").matches) {
        var windowHeight = window.innerHeight;
      }
      var coverHeight = `${windowHeight}px`;
      cards[i].style.height = coverHeight;
    }
    if (cards[i].hasAttribute("data-card-height")) {
      const getHeight = cards[i].getAttribute("data-card-height");
      cards[i].style.height = `${getHeight}px`;
      if (getHeight === "cover") {
        const totalHeight = getHeight;
        cards[i].style.height = coverHeight;
      }
    }
  }
}

// Image Sliders

function sliderImage() {
  const splide = document.getElementsByClassName("splide");
  if (splide.length) {
    const singleSlider = document.querySelectorAll(".single-slider");
    if (singleSlider.length) {
      singleSlider.forEach((e) => {
        const single = new Splide(`#${e.id}`, {
          type: "loop",
          autoplay: true,
          interval: 4000,
          perPage: 1,
        }).mount();
        const sliderNext = document.querySelectorAll(".slider-next");
        const sliderPrev = document.querySelectorAll(".slider-prev");
        sliderNext.forEach((el) =>
          el.addEventListener("click", (el) => {
            single.go(">");
          })
        );
        sliderPrev.forEach((el) =>
          el.addEventListener("click", (el) => {
            single.go("<");
          })
        );
      });
    }

    const doubleSlider = document.querySelectorAll(".double-slider");
    if (doubleSlider.length) {
      doubleSlider.forEach((e) => {
        const double = new Splide(`#${e.id}`, {
          type: "loop",
          autoplay: true,
          interval: 4000,
          arrows: false,
          perPage: 2,
        }).mount();
      });
    }

    const trippleSlider = document.querySelectorAll(".tripple-slider");
    if (trippleSlider.length) {
      trippleSlider.forEach((e) => {
        const tripple = new Splide(`#${e.id}`, {
          type: "loop",
          autoplay: true,
          padding: {
            left: "0px",
            right: "80px",
          },
          interval: 4000,
          arrows: false,
          perPage: 2,
          perMove: 1,
        }).mount();
      });
    }
  }

  const topicSlider = document.querySelectorAll(".topic-slider");
  if (topicSlider.length) {
    var topic = new Splide(".topic-slider", {
      autoplay: false,
      padding: {
        left: "15px",
        right: "40px",
      },
      arrows: false,
      perPage: 3,
      perMove: 1,
    }).mount();
  }
  const storySlider = document.querySelectorAll(".story-slider");
  if (storySlider.length) {
    var topic = new Splide(".story-slider", {
      type: "loop",
      autoplay: false,
      padding: {
        left: "0px",
        right: "40px",
      },
      arrows: false,
      perPage: 4,
      perMove: 1,
    }).mount();
  }
}
// End  Image Sliders

function menuFunction() {
  // Menu Function,  Setting Sidebar Widths
  const menus = document.querySelectorAll(".menu");

  // active menu
  menus.forEach((el) => {
    el.style.display = "block";
  });

  if (menus.length) {
    const menuSidebar = document.querySelectorAll(
      ".menu-box-left, .menu-box-right"
    );
    menuSidebar.forEach((e) => {
      if (e.getAttribute("data-menu-width") === "cover") {
        e.style.width = "100%";
      } else {
        e.style.width = `${e.getAttribute("data-menu-width")}px`;
      }
    });
    const menuSheets = document.querySelectorAll(
      ".menu-box-bottom, .menu-box-top, .menu-box-modal"
    );
    menuSheets.forEach((e) => {
      if (e.getAttribute("data-menu-width") === "cover") {
        e.style.width = "100%";
        e.style.height = "100%";
      } else {
        e.style.width = `${e.getAttribute("data-menu-width")}px`;
        e.style.height = `${e.getAttribute("data-menu-height")}px`;
      }
    });
  }
}

// Closing Menus
function menuClose(el) {
  const activeMenu = document.querySelectorAll(".menu-active");
  const wrappers = document.querySelectorAll(
    ".header, #footer-bar, .page-content"
  );
  for (let i = 0; i < activeMenu.length; i++) {
    activeMenu[i].classList.remove("menu-active");
  }
  for (let i = 0; i < wrappers.length; i++) {
    wrappers[i].style.transform = `translateX(-${0}px)`;
  }
}

// Setting Sidebar Widths
// Open Menu
function menuOpen(menu_data) {
  console.log(menu_data);
  const menuOpen = document.querySelectorAll("[data-menu]");
  const wrappers = document.querySelectorAll(
    ".header, #footer-bar, .page-content"
  );

  // Close Existing Opened Menus
  const activeMenu = document.querySelectorAll(".menu-active");
  for (let i = 0; i < activeMenu.length; i++) {
    activeMenu[i].classList.remove("menu-active");
  }
  // Open Clicked Menu
  const menuData = menu_data;
  document.getElementById(menuData).classList.add("menu-active");
  const menuHider = document.getElementsByClassName("menu-hider")[0];
  menuHider ? menuHider.classList.add("menu-active") : "";

  // Check and Apply Effects
  const menu = document.getElementById(menuData);
  const menuEffect = menu.getAttribute("data-menu-effect");
  const menuLeft = menu.classList.contains("menu-box-left");
  const menuRight = menu.classList.contains("menu-box-right");
  const menuTop = menu.classList.contains("menu-box-top");
  const menuBottom = menu.classList.contains("menu-box-bottom");
  var menuWidth = menu.offsetWidth;
  const menuHeight = menu.offsetHeight;
  const menuTimeout = menu.getAttribute("data-menu-hide");

  if (menuTimeout) {
    setTimeout(() => {
      document.getElementById(menuData).classList.remove("menu-active");
      document
        .getElementsByClassName("menu-hider")[0]
        .classList.remove("menu-active");
    }, menuTimeout);
  }

  if (menuEffect === "menu-push") {
    var menuWidth = document
      .getElementById(menuData)
      .getAttribute("data-menu-width");
    if (menuLeft) {
      for (let i = 0; i < wrappers.length; i++) {
        wrappers[i].style.transform = `translateX(${menuWidth}px)`;
      }
    }
    if (menuRight) {
      for (let i = 0; i < wrappers.length; i++) {
        wrappers[i].style.transform = `translateX(-${menuWidth}px)`;
      }
    }
    if (menuBottom) {
      for (let i = 0; i < wrappers.length; i++) {
        wrappers[i].style.transform = `translateY(-${menuHeight}px)`;
      }
    }
    if (menuTop) {
      for (let i = 0; i < wrappers.length; i++) {
        wrappers[i].style.transform = `translateY(${menuHeight}px)`;
      }
    }
  }
  if (menuEffect === "menu-parallax") {
    var menuWidth = document
      .getElementById(menuData)
      .getAttribute("data-menu-width");
    if (menuLeft) {
      for (let i = 0; i < wrappers.length; i++) {
        wrappers[i].style.transform = `translateX(${menuWidth / 10}px)`;
      }
    }
    if (menuRight) {
      for (let i = 0; i < wrappers.length; i++) {
        wrappers[i].style.transform = `translateX(-${menuWidth / 10}px)`;
      }
    }
    if (menuBottom) {
      for (let i = 0; i < wrappers.length; i++) {
        wrappers[i].style.transform = `translateY(-${menuHeight / 5}px)`;
      }
    }
    if (menuTop) {
      for (let i = 0; i < wrappers.length; i++) {
        wrappers[i].style.transform = `translateY(${menuHeight / 5}px)`;
      }
    }
  }
}

// activate Menus
function activateMenus() {
  const menuActive = document.querySelectorAll("[data-menu-active]")[0];
  if (menuActive) {
    const selectedMenu = menuActive.getAttribute("data-menu-active");
    document
      .querySelectorAll(`#${selectedMenu}`)[0]
      .classList.add("active-nav");
  }
}
// Page Highlights
function highlightColors() {
  const highlightData = document.querySelectorAll("[data-change-highlight]");
  highlightData.forEach((el) =>
    el.addEventListener("click", (e) => {
      const activeHighlight = document.querySelectorAll(".highlight-active");
      for (let i = 0; i < activeHighlight.length; i++) {
        activeHighlight[i].classList.remove("highlight-active");
      }
      el.classList.add("highlight-active");
      const highlight = el.getAttribute("data-change-highlight");
      const pageHighlight = document.querySelectorAll(".page-highlight");
      if (pageHighlight.length) {
        pageHighlight.forEach((e) => {
          e.remove();
        });
      }
      const loadHighlight = document.createElement("link");
      loadHighlight.rel = "stylesheet";
      loadHighlight.className = "page-highlight";
      loadHighlight.type = "text/css";
      loadHighlight.href = `styles/highlights/highlight_${highlight}.css`;
      document.getElementsByTagName("head")[0].appendChild(loadHighlight);
      document.body.setAttribute("data-highlight", `highlight-${highlight}`);
      localStorage.setItem(`${pwaName}-Highlight`, highlight);
    })
  );
  const rememberHighlight = localStorage.getItem(`${pwaName}-Highlight`);
  if (rememberHighlight) {
    const loadHighlight = document.createElement("link");
    loadHighlight.rel = "stylesheet";
    loadHighlight.className = "page-highlight";
    loadHighlight.type = "text/css";
    loadHighlight.href = `styles/highlights/highlight_${rememberHighlight}.css`;
    if (!document.querySelectorAll(".page-highlight").length) {
      document.getElementsByTagName("head")[0].appendChild(loadHighlight);
      document.body.setAttribute(
        "data-highlight",
        `highlight-${rememberHighlight}`
      );
    }
  }
}

// Activate Remembered Highlight
function selectHighlight() {
  const rememberHighlight = localStorage.getItem(`${pwaName}-Highlight`);
  if (rememberHighlight) {
    document
      .querySelectorAll(`[data-change-highlight="${rememberHighlight}"]`)[0]
      .classList.add("highlight-active");
    document.body.setAttribute("data-highlight", rememberHighlight);
  }
}

function footerBar6() {
  const footerBar6 = document.querySelectorAll(".footer-bar-6")[0];
  if (footerBar6) {
    const footerBar6_select = document.querySelectorAll(
      ".footer-bar-6 .active-nav"
    )[0];
    const footerBar6_circle = document.querySelectorAll(
      ".footer-bar-6 .circle-nav"
    )[0];
    if (footerBar6_select) {
      footerBar6_select.insertAdjacentHTML("beforeend", "<em></em>");
    }
    if (footerBar6_circle) {
      footerBar6_circle.insertAdjacentHTML(
        "beforeend",
        "<strong><u></u></strong>"
      );
    }
  }
}

// Dark Mode
function checkDarkMode() {
  const toggleDark = document.querySelectorAll("[data-toggle-theme]");
  function activateDarkMode() {
    document.body.classList.add("theme-dark");
    document.body.classList.remove("theme-light", "detect-theme");
    for (let i = 0; i < toggleDark.length; i++) {
      toggleDark[i].checked = "checked";
    }
    localStorage.setItem(`${pwaName}-Theme`, "dark-mode");
  }
  function activateLightMode() {
    document.body.classList.add("theme-light");
    document.body.classList.remove("theme-dark", "detect-theme");
    for (let i = 0; i < toggleDark.length; i++) {
      toggleDark[i].checked = false;
    }
    localStorage.setItem(`${pwaName}-Theme`, "light-mode");
  }
  function removeTransitions() {
    const falseTransitions = document.querySelectorAll(
      ".btn, .header, #footer-bar, .menu-box, .menu-active"
    );
    for (let i = 0; i < falseTransitions.length; i++) {
      falseTransitions[i].style.transition = "all 0s ease";
    }
  }
  function addTransitions() {
    const trueTransitions = document.querySelectorAll(
      ".btn, .header, #footer-bar, .menu-box, .menu-active"
    );
    for (let i = 0; i < trueTransitions.length; i++) {
      trueTransitions[i].style.transition = "";
    }
  }

  function setColorScheme() {
    const isDarkMode = window.matchMedia(
      "(prefers-color-scheme: dark)"
    ).matches;
    const isLightMode = window.matchMedia(
      "(prefers-color-scheme: light)"
    ).matches;
    const isNoPreference = window.matchMedia(
      "(prefers-color-scheme: no-preference)"
    ).matches;
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addListener((e) => e.matches && activateDarkMode());
    window
      .matchMedia("(prefers-color-scheme: light)")
      .addListener((e) => e.matches && activateLightMode());
    if (isDarkMode) activateDarkMode();
    if (isLightMode) activateLightMode();
  }

  // Activating Dark Mode
  const darkModeSwitch = document.querySelectorAll("[data-toggle-theme]");
  darkModeSwitch.forEach((el) =>
    el.addEventListener("click", (e) => {
      if (document.body.className == "theme-light") {
        removeTransitions();
        activateDarkMode();
      } else if (document.body.className == "theme-dark") {
        removeTransitions();
        activateLightMode();
      }
      setTimeout(() => {
        addTransitions();
      }, 350);
    })
  );

  // Set Color Based on Remembered Preference.
  if (localStorage.getItem(`${pwaName}-Theme`) == "dark-mode") {
    for (let i = 0; i < toggleDark.length; i++) {
      toggleDark[i].checked = "checked";
    }
    document.body.className = "theme-dark";
  }
  if (localStorage.getItem(`${pwaName}-Theme`) == "light-mode") {
    document.body.className = "theme-light";
  }
  if (document.body.className == "detect-theme") {
    setColorScheme();
  }

  // Detect Dark/Light Mode
  const darkModeDetect = document.querySelectorAll(".detect-dark-mode");
  darkModeDetect.forEach((el) =>
    el.addEventListener("click", (e) => {
      document.body.classList.remove("theme-light", "theme-dark");
      document.body.classList.add("detect-theme");
      setTimeout(() => {
        setColorScheme();
      }, 50);
    })
  );
}

// Back to Top
function backUp() {
  const backToTop = document.querySelectorAll(
    ".back-to-top-icon, .back-to-top-badge, .back-to-top"
  );
  if (backToTop) {
    backToTop.forEach((el) =>
      el.addEventListener("click", (e) => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      })
    );
  }
}

// Scroll Ads
function scrollAds() {
  const scrollItems = document.querySelectorAll(
    ".scroll-ad, .header-auto-show"
  );
  if (scrollItems.length) {
    const scrollAd = document.querySelectorAll(".scroll-ad");
    const scrollHeader = document.querySelectorAll(".header-auto-show");
    const pageTitle = document.querySelectorAll(".page-title");
    window.addEventListener("scroll", () => {
      if (document.querySelectorAll(".scroll-ad, .header-auto-show").length) {
        function showScrollAd() {
          scrollAd[0].classList.add("scroll-ad-visible");
        }
        function hideScrollAd() {
          scrollAd[0].classList.remove("scroll-ad-visible");
        }
        function showHeader() {
          scrollHeader[0].classList.add("header-active");
        }
        function hideHeader() {
          scrollHeader[0].classList.remove("header-active");
        }
        function hideTitle() {
          pageTitle[0].style.opacity = "0";
        }
        function showTitle() {
          pageTitle[0].style.opacity = "1";
        }
        const window_height = window.outerWidth;
        const total_scroll_height = document.documentElement.scrollTop;
        const inside_header = total_scroll_height <= 80;
        const passed_header = total_scroll_height >= 80;
        const inside_title = total_scroll_height <= 40;
        const passed_title = total_scroll_height >= 40;
        const inside_footer = window_height - total_scroll_height + 1000 <= 150;
        if (scrollAd.length) {
          inside_header ? hideScrollAd() : null;
          passed_header ? showScrollAd() : null;
          inside_footer ? hideScrollAd() : null;
        }
        if (scrollHeader.length) {
          inside_header ? hideHeader() : null;
          passed_header ? showHeader() : null;
        }
        if (pageTitle.length) {
          inside_title ? showTitle() : null;
          passed_title ? hideTitle() : null;
        }
      }
    });
  }
}

function imageSlider() {
  const topicSlider = document.querySelectorAll(".topic-slider");
  if (topicSlider.length) {
    var topic = new Splide(".topic-slider", {
      type: "loop",
      autoplay: false,
      padding: {
        left: "15px",
        right: "40px",
      },
      arrows: false,
      perPage: 3,
      perMove: 1,
    }).mount();
  }
}

// init template
function init_template() {
  // Removing Preloader
  //setTimeout(() => {
  //const preloader = document.getElementById("preloader");
  //if (preloader) {
  //preloader.classList.add("preloader-hide");
  //}
  //}, 150);

  // cards
  if (cards.length) {
    cardExtender();
    window.addEventListener("resize", cardExtender);
  }

  //footer
  footerBar6();

  // Check Dark Mode
  checkDarkMode();

  // menus
  menuFunction();

  // scrollAds
  scrollAds();
  // console.log("called init template");

  // image slider
  //imageSlider();

  sliderImage();
}

function dataMenuLoad() {
  //menuFunction();
  activateMenus();
  /*shareLinks();*/
  cardExtender();
  backUp();
}

export {
  menuFunction,
  cardExtender,
  activateMenus,
  menuOpen,
  menuClose,
  init_template,
  dataMenuLoad,
  sliderImage,
};
