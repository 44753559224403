// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyADPsvXFwWfIP_LMpqlcmWNYXRSq2oUcxo",
  authDomain: "pasjob-8867e.firebaseapp.com",
  projectId: "pasjob-8867e",
  storageBucket: "pasjob-8867e.appspot.com",
  messagingSenderId: "336753525814",
  appId: "1:336753525814:web:be4428c780de3856cc0497",
  measurementId: "G-7R9LJV8H7T",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const messaging = getMessaging(firebaseApp);

export default messaging;
