import { createStore } from "vuex";

export default createStore({
  state: {
    isAuthenticated: false,
    userType: "",
    token: "",
    refresh: "",
    token_valid: false,
    master_data: {},
    apply: [],
    account: [],
    userProfileId: "",
    userSearchQuery: "",
    jobList: {
      job: [],
      next: ''
    }
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem("token") && localStorage.getItem("account")) {
        state.token = localStorage.getItem("token");
        state.refresh = localStorage.getItem("refresh");
        state.userType = localStorage.getItem("userType");
        state.account = JSON.parse(localStorage.getItem("account"));
        //state.token_valid = localStorage.getItem("token_valid") === "true";
        state.isAuthenticated = true;
                state.master_data = JSON.parse(localStorage.getItem("master_data"));
        if (state.account) {
          if (state.account.userType === "J") {
            state.apply = JSON.parse(localStorage.getItem("apply"));
            state.userProfileId = localStorage.getItem("userProfileId");
          }
        }
      } else {
        state.token = "";
        state.refresh = "";
        state.userType = "";
        state.isAuthenticated = false;
                state.master_data = "";
        if (state.userType === "J") {
          state.userProfileId = "";
          state.apply = "";
        }
      }
    },
    setListData(state, data) {
      state.jobList = data;
    },
    setFindUserQuery(state, query) {
      state.userSearchQuery = query;
    },
    addAccount(state, data) {
      state.account = data;
      localStorage.setItem("account", JSON.stringify(data));
    },
    setId(state, id) {
      state.userProfileId = id;
    },
    addApply(state, data) {
      state.apply = data;
      localStorage.setItem("apply", JSON.stringify(data));
    },
    addMasterData(state, data) {
      state.master_data = data;
      localStorage.setItem("master_data", JSON.stringify(data));
    },
    setUserType(state, user_type) {
      state.userType = user_type;
    },
    setToken(state, token) {
      state.token = token;
      state.isAuthenticated = true;
      //state.token_valid = true;
    },
    setRefresh(state, token) {
      state.refresh = token;
    },
    setTokenNotValid(state) {
      state.token_valid = false;
    },
    setUserProfile(state, data) {
      state.user_profile = data;
    },
    //logout
    removeUserInformation(state) {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh");
      localStorage.removeItem("userType");
      localStorage.removeItem("master_data");
      localStorage.removeItem("userProfileId");
      localStorage.removeItem("account");
      localStorage.removeItem("apply");

      state.userType = "";
      state.refresh = "";
      state.token = "";
      state.master_data = "";
      state.isAuthenticated = false;
            state.userProfileId = "";
      state.apply = [];
      state.account = "";
    },
  },
  actions: {},
  modules: {},
});
