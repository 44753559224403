<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <Header :add_class="['header-auto-show']" />

    <Footer logged />

    <div class="page-title page-title-fixed">
      <h1>404</h1>
      <a
        href="#"
        class="page-title-icon shadow-xl bg-theme color-theme"
        data-menu="menu-share"
        ><i class="fa fa-share-alt"></i
      ></a>
      <a
        href="#"
        class="
          page-title-icon
          shadow-xl
          bg-theme
          color-theme
          show-on-theme-light
        "
        data-toggle-theme
        ><i class="fa fa-moon"></i
      ></a>
      <a
        href="#"
        class="
          page-title-icon
          shadow-xl
          bg-theme
          color-theme
          show-on-theme-dark
        "
        data-toggle-theme
        ><i class="fa fa-lightbulb color-yellow-dark"></i
      ></a>
      <a
        @click="menuOpen('menu-main')"
        class="page-title-icon shadow-xl bg-theme color-theme"
        data-menu="menu-main"
        ><i class="fa fa-bars"></i
      ></a>
    </div>
    <div class="page-title-clear"></div>

    <div class="page-content">
      <div class="card card-style p-4 text-center">
        <p class="font-600 color-highlight mb-0">Error 404 - Page Not Found</p>
        <h1>Wooops! It's not Here!</h1>
        <p>
          Sorry, the page you're looking for cannot be found. How about checking
          some of the other awesome pages we have around?
        </p>
        <router-link
          :to="{ name: 'Home' }"
          class="
            btn btn-m btn-center-m
            font-700
            gradient-highlight
            rounded-s
            mb-3
          "
          >Back Home</router-link
        >
      </div>
    </div>
    <!-- Page content ends here-->

    <!-- Main Menu-->
    <div
      id="menu-main"
      class="menu menu-box-left rounded-0"
      data-menu-width="280"
      data-menu-active="nav-welcome"
    >
      <MenuMain @close="menuClose" />
    </div>

    <!-- Share Menu-->
    <div
      id="menu-share"
      class="menu menu-box-bottom rounded-m"
      data-menu-load="menu-share.html"
      data-menu-height="370"
    ></div>

    <!-- Colors Menu-->
    <div
      id="menu-colors"
      class="menu menu-box-bottom rounded-m"
      data-menu-load="menu-colors.html"
      data-menu-height="480"
    >
      <MenuColors @close="menuClose" />
    </div>
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";

import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import MenuMain from "@/components/menu/MenuMain";
import MenuColors from "@/components/menu/MenuColors";

export default {
  components: {
    Header,
    Footer,
    MenuMain,
    MenuColors,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  mounted() {
    init_template();
    setTimeout(() => {
      this.isLoading = false;
    }, 150);
  },
  methods: {
    menuClose,
    menuOpen,
  },
};
</script>
